import * as types from "./types";
import states from './state'

const MAPPING = {
  [types.USER_LOGIN]: 'user',
  [types.USER_LOGOUT]: 'user',
}

const reducer = (state = states, action) => {
  const newState = {...state};
  if(action.type === types.USER_LOGOUT)
    newState['user'] = ''
  else newState[MAPPING[action.type]] = action.payload
  return  newState
}

export * from './actions'
export default reducer
import api from '../../api/config'

const report = api.report
// cervixOpening, headDrop, bloodPressureMin, bloodPressureMax
// amnioticFlud, infiltration, oxytocinUI, oxytocinDrops, urineProtein, urineAseton, urineVolume
// medicationsAndIVFluid
const obj = [
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getReports(1),
        'data': [
            {reportedDate: '29-07-2023 16:01:43', fetalHeartRate: 100, pulse: 88, contractionTimes:5, contractionDuration: 42, cervixOpening: 8, headDrop: 2},
            {reportedDate: '29-07-2023 15:37:43', fetalHeartRate: 110, pulse: 88, contractionTimes:5, contractionDuration: 42, amnioticFluid: 'U', infiltration:5},
            {reportedDate: '29-07-2023 15:04:43', fetalHeartRate: 167, pulse: 88, contractionTimes:4, contractionDuration: 41, oxytocinDrops: 31, oxytocinUI: 20},
            {reportedDate: '29-07-2023 14:31:43', fetalHeartRate: 124, pulse: 88, contractionTimes:3, contractionDuration: 25, urineProtein:1},
            {reportedDate: '29-07-2023 14:03:43', fetalHeartRate: 200, pulse: 80, contractionTimes:2, contractionDuration: 25, urineVolume:300},
            {reportedDate: '29-07-2023 13:27:43', fetalHeartRate: 171, pulse: 80, contractionTimes:1, contractionDuration: 10, temperature: 37, urineAseton: 20, cervixOpening: 5, headDrop: 4, bloodPressureMin: 70, bloodPressureMax: 120},
        ]
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getNotes(1),
        'data': [
            {reportedDate: '29-07-2023 15:37:43', text: "Sudah makan"},
            {reportedDate: '29-07-2023 15:37:43', text: "Minum Esteh manis"}
        ]
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getReports(2),
        'data': [
            {reportedDate: '29-07-2023 15:37:43', fetalHeartRate: 140, pulse: 88, contractionTimes:3, contractionDuration: 40},
            {reportedDate: '29-07-2023 15:04:43', fetalHeartRate: 140, pulse: 88, contractionTimes:3, contractionDuration: 40},
            {reportedDate: '29-07-2023 14:31:43', fetalHeartRate: 140, pulse: 88, contractionTimes:3, contractionDuration: 40, cervixOpening: 10},
        ]
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getNotes(2),
        'data': []
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getReports(3),
        'data': []
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': report.getNotes(3),
        'data': []
    },
]

export default obj
import httpApi from '@/utils/http-api'
import api from '../../api/config'

const url = api.patient
const obj = {
    getAll() {
        const path = url.getAll
        return httpApi.getRequest(path)
    },
    getSearchOptions(options) {
        const path = url.getSearchOptions
        return httpApi.getRequest(path, options)
    },
    getInfo(id) {
        const path = url.getInfo(id)
        return httpApi.getRequest(path)
    },
    getById(id) {
        const path = url.getById(id)
        return httpApi.getRequest(path)
    }
}

export default obj
import * as types from './types'
import api from '../../../api/controller/patient'

const fetchAllPatients = (form) => {
    return async (dispatch) => {
        const { data } = await api.getAll(form)
        dispatch({type: types.PATIENT_SET_ALL, payload: data.patients})
        dispatch({type: types.PATIENT_SET_PAGING, payload: data.paging})
    }
}
const setPatient = (patient) => {
    return async (dispatch) => {
        dispatch({type: types.PATIENT_SET, payload: patient})
    }
}
const fetchPatientInfo = (patientId) => {
    return async (dispatch) => {
        const { data } = await api.getInfo(patientId)
        dispatch({type: types.PATIENT_SET_INFO, payload: data})
    }
}
const fetchPatient = (patientId) => {
    return async (dispatch) => {
        const { data } = await api.getById(patientId)
        dispatch({type: types.PATIENT_SET, payload: data})
    }
}
const fetchOptions = (options) => {
    return async (dispatch) => {
        const { data } = await api.getSearchOptions(options)
        dispatch({type: types.PATIENT_SET_OPTIONS, payload: data})
    }
}

export {
    fetchAllPatients,
    setPatient,
    fetchPatientInfo,
    fetchPatient,
    fetchOptions,
}
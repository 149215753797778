const api = {
    user: {
        login: '/api/user/login',
        user: '/api/user/user',
        logout: '/api/user/logout',
        schedule: '/api/user/schedule'
    },
    patient: {
        getAll: '/api/patient/getAll',
        getSearchOptions: '/api/patient/options',
        getById: (id) => '/api/patient/' + id,
        getInfo: (id) => '/api/patient/' + id + '/info',
    },
    report: {
        getReports: (id) => '/api/report/' + id,
        getNotes: (id) => '/api/report/' + id + '/note',
    },
    homepage: {
        getCarousel: '/api/homepage/carousel'
    }
}

export default api
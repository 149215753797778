import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import TypingAnimation from "../components/template/TypingAnimation";
import { BellIcon, ClockIcon, EnvelopeIcon, UserIcon } from "@heroicons/react/24/outline";
import Card from "../layout/Card";
import { toggleModal } from "../store/modules/universal";
import { UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL, UNIVERSAL_TOGGLE_NOTIFICATION_MODAL } from "../store/modules/universal/types";
import { padNumber } from "../utils/common-function";
import ContentContainer from "../layout/ContentContainer";
import Info from "../components/template/Info";
import { fetchCarousel } from "../store/modules/homepage";
import { Link } from "react-router-dom";

const textArr = [
    "Selamat datang kembali!",
    "Semoga harimu berjalan lancar!"
]

export default function Home() {
    const dispatch = useDispatch()
    const user = useSelector(state => state['userReducer'].user)
    const carousel = useSelector(state => state['homepageReducer'].carousel)
    const [positionTop, setPositionTop] = useState(0)
    const [timer, setTimer] = useState('00:00')
    const schedule = useMemo(() => {
        return {
            name: 'Pasien1',
            room: 'A103',
            priority: 1,
            appointedTime: new Date(Date.now() + 30*60*1000)
        }
    }, [])

    useEffect(() => {
        dispatch(fetchCarousel())
        const timerWalk = setInterval(getTimer, 1000)
        
        function getTimer() {
            const now = Date.now()
            const delta = schedule.appointedTime - now
            
            const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((delta % (1000 * 60)) / 1000)

            setTimer(padNumber(minutes, 2) + ':' + padNumber(seconds, 2))
        }
        function handleScroll() {
            setPositionTop(document.body.scrollTop || document.documentElement.scrollTop)
        }

        document.addEventListener("scroll", handleScroll)
        return () => {
            document.removeEventListener("scroll", handleScroll)
            clearInterval(timerWalk)
        }

    }, [dispatch, schedule.appointedTime])

    function toggleNotificationModal() { 
        dispatch(toggleModal(UNIVERSAL_TOGGLE_NOTIFICATION_MODAL))
    }

    function toggleAnnouncementModal() {
        dispatch(toggleModal(UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL))
    }

    function getName() {
        if(user.nickname === undefined) return ''
        return user.nickname ? user.nickname : user.fullName.split(' ')[0]
    }

    function cardBehindCard(depth=2) {
        if(!depth) return
        return (
            <div className="top-1 left-0 absolute w-full h-full px-4 -z-10">
                <Card className="bg-white w-full h-full relative">
                    {cardBehindCard(depth-1)}
                </Card>
            </div>
        )
    }

    function renderCarousel(){
        return carousel.map((item, idx) => {
            return (
                <div key={idx} className={`${idx === 0 ? '' : 'ml-4'} w-11/12`}>
                    <div className="text-xl text-primary font-bold">{item.title}</div>
                    <a href={item.url} target="_blank" rel="noreferrer">
                        <Card className="mt-2 drop-shadow transform-gpu rounded flex justify-end overflow-hidden bg-primary">
                            <div className="w-11/12 flex justify-center items-center relative z-0">
                                <div className="w-[200%] absolute left-px aspect-square flex items-center rounded-full overflow-hidden">
                                    <img className="w-1/2" src={item.image} alt={item.text} />
                                </div>
                                <img className="opacity-0" src={item.image} alt={item.text} />
                            </div>
                        </Card>
                        <div className="text-sm font-semibold text-gray-600 relative z-10 mt-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
                            {item.text}
                        </div>
                    </a>
                    <div className="text-xxs text-gray-600 relative z-10 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        Diambil dari <a href={item.source} target="_blank" rel="noreferrer" className="text-primary underline">{item.source}</a>
                    </div>
                </div>
            )
        })
    }

    return(
        <>
            <div className={`p-4 flex justify-between text-white bg-primary sticky top-0 z-50
                ${positionTop > 96 ? 'shadow-lg' : positionTop > 5 ? 'shadow' : '' }
            `}>
                <div className="font-medium">Partograf Digital</div>
                <div className="flex">
                    <div className="w-6 h-6 mr-4" onClick={toggleAnnouncementModal}><EnvelopeIcon/></div>
                    <div className="w-6 h-6" onClick={toggleNotificationModal}><BellIcon/></div>
                </div>
            </div>
            <div className="rounded-b-2xl text-white bg-primary shadow-lg px-4 pb-4 flex justify-between items-end relative z-10">
                <div className="">
                    <div className="text-[10px]">@{user.workPlace}</div>
                    <div className="text-3xl font-medium">Hi, { getName() }</div>
                    <div className="text-xs h-3 flex items-center"><TypingAnimation textArr={textArr} barColor="bg-white"/></div>
                </div>
                <div className="w-20 h-20 rounded-lg overflow-hidden shadow">
                    {
                        user.photo ?
                        <img src={user.photo} alt=""/>
                        : <div className='text-primary bg-white border-white p-3'><UserIcon/></div>
                    }
                </div>
            </div>
            <ContentContainer className="py-0 mt-4 mb-10 relative z-10">
                <Card className="bg-white drop-shadow rounded-sm p-2 flex justify-evenly ">
                    <Info className="text-center" label='PASIEN TERBANTU' value={user.patientHelped}/>
                    <div className='border-l'/>
                    <Info className="text-center" label='DATA TERISI' value={user.dataInserted}/>
                    <div className='border-l'/>
                    <Info className="text-center text-primary" label='Pasien Aktif' value={3}/>
                </Card>
                <div className="flex justify-between items-end mt-2">
                    <div className="text-xl text-primary font-bold">Jadwal Anda</div>
                    <Link to="/schedule" className="pb-1 text-xs text-gray-500 font-medium underline">Lihat semua</Link>
                </div>
                <div className="mt-2 relative">
                    <Card className="p-4 relative z-0 w-full drop-shadow shadow h-full bg-white">
                        <div className="flex">
                            <div className="relative flex justify-center items-center w-14 min-h-[3.5rem] overflow-hidden rounded mr-3">
                                <UserIcon className="absolute h-full border-4 border-gray-300 bg-gray-300 text-white"/>
                            </div>
                            <div className="flex flex-grow flex-col justify-between">
                                <div className={`text-center text-xs px-2 py-px rounded text-white font-semibold 
                                    ${schedule.priority === 3 ? "bg-green" :
                                    schedule.priority === 2 ? "bg-orange" :
                                    "bg-red"}
                                `}>PRIORITAS { schedule.priority }</div>
                                <div className="text-gray-500 font-bold text-base">{ schedule.name }</div>
                            </div>
                        </div>
                        <div className="bg-gray-100 mt-4 text-xs tracking-wider rounded p-3 flex items-center justify-center text-gray-500 font-medium">
                            <div className="w-6 h-6 mr-2"><ClockIcon/></div>
                            Pada ruang&nbsp;<span className="text-primary font-semibold">{schedule.room}</span>&nbsp;dalam&nbsp;<span className="text-primary font-semibold w-8 text-center">{timer}</span>
                        </div>
                    </Card>
                    <div className="left-0 top-0 absolute w-full h-full -z-10">
                        <div className="relative w-full h-full">
                            {cardBehindCard()}
                        </div>
                    </div>
                </div>
            </ContentContainer>
            <ContentContainer className="mt-4 pb-6 bg-white overflow-x-scroll flex">
                {renderCarousel()}
            </ContentContainer>
            {/* <HorizontalSeparator className="mx-4"/> */}
            <ContentContainer className="pb-20 flex-grow bg-white"/>
        </>
    )
}
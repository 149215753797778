import * as types from "./types";
import states from './state'

const MAPPING = {
  [types.REPORT_SET]: 'reports',
  [types.REPORT_SET_NOTE]: 'notes',
  [types.REPORT_SET_PATIENT]: 'reportPatientId',
  [types.REPORT_SET_PATIENT_NAME]: 'reportPatientName'
}

const reducer = (state = states, action) => {
  const newState = {...state};
  newState[MAPPING[action.type]] = action.payload
  return newState
}

export * from './actions'
export default reducer
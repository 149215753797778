import { NavLink } from "react-router-dom"
import { UserGroupIcon, HomeIcon, DocumentTextIcon, UserIcon } from '@heroicons/react/24/outline'
import { 
    UserGroupIcon as UserGroupIconActive,
    HomeIcon as HomeIconActive, 
    DocumentTextIcon as DocumentTextIconActive, 
    UserIcon as UserIconActive
} from '@heroicons/react/24/solid'
import { useMemo } from "react"

export default function Navbar({ toggleAddModal, isAddModalShown, closeAddModal, toggleUserModal }){
    const iconSize = "w-8 h-8"

    const ICON_MAP = useMemo(() => {
        return {
            home: [<HomeIcon className={`${iconSize}`}/>, <HomeIconActive className={`${iconSize}`}/>],
            patient: [<UserGroupIcon className={`${iconSize}`}/>, <UserGroupIconActive className={`${iconSize}`}/>],
            report: [<DocumentTextIcon className={`${iconSize}`}/>, <DocumentTextIconActive className={`${iconSize}`}/>],
            user: [<UserIcon className={`${iconSize}`}/>, <UserIconActive className={`${iconSize}`}/>],
        }
    }, [])

    function getIcon(isActive=false, key) {
        return (
            <div className={`${iconSize} flex items-center justify-center`}>
                {isActive ? ICON_MAP[key][1] : ICON_MAP[key][0] }
            </div>
        )
    }

    return (
        <div className="flex items-center justify-evenly w-full bg-white  text-primary border-t select-none pb-3">
            <NavLink onClick={closeAddModal} to="" className="flex select-none flex-col justify-center items-center p-2">
                {({isActive}) => (getIcon(isActive, 'home'))}
            </NavLink>
            <NavLink onClick={closeAddModal} to="patient" className="flex select-none flex-col justify-center items-center p-2">
                {({isActive}) => (getIcon(isActive, 'patient'))}
            </NavLink>
            <div className="flex select-none flex-col justify-center items-center p-2 cursor-pointer" onClick={toggleAddModal}>
                <div className={`w-7 h-7 flex items-center justify-center rounded-full border-2 border-primary
                    transition-colors
                    ${isAddModalShown ? 'bg-primary text-white' : 'bg-white text-primary'}
                `}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
            </div>
            <NavLink onClick={closeAddModal} to="report" className="flex select-none flex-col justify-center items-center p-2">
                {({isActive}) => (getIcon(isActive, 'report'))}
            </NavLink>
            <div onClick={toggleUserModal} className="group/user-navbar flex relative select-none flex-col justify-center items-center p-2">
                { getIcon(false, 'user') }
                <div className="absolute group-active/user-navbar:opacity-100 opacity-0 transition-opacity">{ getIcon(true, 'user') }</div>
            </div>
        </div>
    )
}
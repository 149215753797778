import { useEffect, useState } from 'react'
import CustomInput from '../template/CustomInput'
import InputNumber from '../template/InputNumber'
import HorizontalSeparator from '../../layout/HorizontalSeparator'
import ModalWithClose from '../template/ModalWithClose'
import { dialogModal } from '../../utils/common-function'

export default function AddPatientModal({ isActive, toggleModal }){
    const [form, setForm] = useState({})

    const handleMaskClick = () => {
        toggleModal()
    }

    useEffect(() => {
        if(!isActive) return
        
        setForm({})
    
    }, [isActive])

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target
        setForm(state => ({
            ...state,
            [name]: type === 'checkbox' ? checked : value
        }))
    }

    function addPatient() {
        dialogModal({
            type: 'info',
            title: 'Apakah kamu sudah yakin?',
            message: 'Data yang sudah terkirim nanti tidak dapat diganti lho...'
        }).then(({confirmed}) => {
            if(!confirmed) return
            console.log('asd')
        })
    }

    return (
        <>
            <ModalWithClose title="Tambah Pasien" helmetActive={isActive}
            noBorder func={addPatient}
            closeClick={handleMaskClick} isActive={isActive}>
                <HorizontalSeparator title='Tentang Pasien' className='mt-2 font-semibold text-primary'/>
                <CustomInput type="text" className="px-2 py-1 border-b mt-6 text-sm" placeholderMove placeholder="Nama" value={form.name} onChange={handleChange} name="name"/>
                <InputNumber className="px-2 py-1 border-b mt-6 text-sm"
                value={form.nik} onChange={handleChange} placeholderMove name="nik" 
                placeholder="NIK"/>
                <CustomInput type="text" className="px-2 py-1 border-b mt-6 text-sm" placeholderMove placeholder="Ruang" value={form.room} onChange={handleChange} name="room"/>
                <div className='flex'>
                    <div className='w-1/3 pr-2 text-sm'><InputNumber placeholder="Gravida" placeholderMove max="20" onChange={handleChange} value={form.gravida} name="gravida"/></div>
                    <div className='w-1/3 px-2 text-sm'><InputNumber placeholder="Paritas" placeholderMove max="20" onChange={handleChange} value={form.paritas} name="paritas"/></div>
                    <div className='w-1/3 pl-2 text-sm'><InputNumber placeholder="Abortus" placeholderMove max="20" onChange={handleChange} value={form.abortus} name="abortus"/></div>
                </div>
                <HorizontalSeparator title='Informasi Tambahan' className='mt-12 font-semibold text-primary'/>
                <CustomInput type="text" className="px-2 py-1 border-b mt-6 text-sm" placeholderMove placeholder="Email" value={form.email} onChange={handleChange} name="email"/>
                <CustomInput type="text" className="px-2 py-1 border-b mt-6 text-sm" placeholderMove placeholder="Telepon" value={form.handphone} onChange={handleChange} name="handphone"/>
                <CustomInput type="text" className="px-2 py-1 border-b mt-6 text-sm" placeholderMove placeholder="Alamat" value={form.room} onChange={handleChange} name="address"/>
            </ModalWithClose>
        </>
    )
}
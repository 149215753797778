import { useDispatch, useSelector } from "react-redux"
import BackLayout from "../layout/BackLayout"
import ContentContainer from "../layout/ContentContainer"
import { fetchPatientInfo, fetchPatient } from '../store/modules/patient'
import { fetchReports, fetchNotes } from '../store/modules/report'
import { useLoaderData } from "react-router"
import { useEffect } from "react"
import { UserIcon } from '@heroicons/react/24/outline'
import Card from "../layout/Card"
import { getDate, getDateString, getTime } from "../utils/common-function"
import Info from "../components/template/Info"

export async function loader({ params }){
    const { id } = params
    return { patientId: id }
}

//{reportedDate: '29-07-2023 15:37:43, fetalHeartRate: 140, pulse: 88, contraction:{times:3, duration: 40}},
function PartografHistoryItem({ partograf, idx }){

    function Info({ className, label, value, noLabel, valueClassName }){
        return (
            <div className={`flex flex-col tracking-widest ${className}`}>
                <div className={`${noLabel ? 'hidden' : ''} text-[10px] text-gray-400 underline`}>{label.toUpperCase()}</div>
                <div className={`font-bold text-gray-500 ${valueClassName}`}>{value}</div>
            </div>
        )
    }

    return (
        <div className="flex relative z-0">
            <div className="w-12 flex justify-center items-center">
                <div className="absolute h-full w-1 bg-primary"/>
                <div className={`bg-primary w-5 h-5 rounded-full relative z-10 flex items-center justify-center`}>
                    <div className="w-3 h-3 bg-white rounded-full"/>
                </div>
            </div>
            <Card className="flex flex-grow justify-evenly bg-white drop-shadow p-2 my-2 mr-4">
                <div className="flex flex-col items-center pr-1">
                    <div className="text-base font-bold text-gray-500">{ getTime(partograf.reportedDate) }</div>
                    <div className="text-[10px] text-gray-400">{ getDate(partograf.reportedDate) }</div>
                </div>
                <span className="h-full border-l"/>
                <Info className="px-1 items-center justify-center" label="DJJ" value={partograf.fetalHeartRate}/>
                <span className="h-full border-l"/>
                <Info className="px-1 items-center justify-center" label="Nadi" value={partograf.pulse}/>
                <span className="h-full border-l"/>
                <Info className="pl-1 items-center justify-center" label="Kontraksi" value={`${partograf.contractionTimes}/${partograf.contractionDuration}"`}/>
            </Card>
        </div>
    )
}

export default function Patient(){
    const dispatch = useDispatch()

    const patient = useSelector(state => state['patientReducer'].patient)
    const patientInfo = useSelector(state => state['patientReducer'].patientInfo)
    const reports = useSelector(state => state['reportReducer'].reports)
    const notes = useSelector(state => state['reportReducer'].notes)
    const { patientId } = useLoaderData()

    useEffect(() => {
        dispatch(fetchPatientInfo(patientId))
        dispatch(fetchReports(patientId))
        dispatch(fetchNotes(patientId))
        if(JSON.stringify(patient) === '{}') dispatch(fetchPatient(patientId))
    }, [dispatch, patientId, patient])

    function renderHistories(){
        if(reports.length === 0) return (
            <Card className="text-xs bg-white drop-shadow-lg text-gray-500 font-bold px-16 py-1 w-fit my-4 mx-auto">BELUM ADA</Card>
        )
        return reports.map((p, idx) => {
            return <PartografHistoryItem key={idx} partograf={p} idx={idx}/>
        })
    }

    function renderNotes(){
        return notes.map((n, idx) => {
            return (
                <div key={idx} className="text-gray-600 my-2">
                    <div className="flex items-center">
                        <span className="w-1 h-1 bg-gray-500 rounded-full"/>
                        <span className="ml-4 text-xs text-gray-500">{getDateString(n.reportedDate)}</span>
                    </div>
                    <div className="ml-5 font-semibold">
                        {n.text}
                    </div>
                </div>
            )
        })
    }

    function getNotes() {
        if(notes.length === 0) return
        return (
            <Card className={`bg-white transform-gpu drop-shadow-lg mt-4 overflow-hidden px-3`}>
                <div className="relative z-10 pt-6 text-base font-bold text-gray-500 tracking-widest">CATATAN</div>
                <div className="py-2 px-4 mt-6 mb-8 bg-gray-200 rounded shadow-inner">
                    {renderNotes()}
                </div>
            </Card>
        )
    }

    return (
        <BackLayout title='Informasi Pasien'>
            <ContentContainer className="text-xs pb-20">
                <Card className={`bg-white text-primary-dark transform-gpu drop-shadow-lg p-3`}>
                    <div className="flex">
                        <div className="flex-grow rounded-lg mr-3 overflow-hidden relative flex justify-center">
                            <UserIcon className="absolute h-full border-8 border-gray-300 bg-gray-300 text-white rounded-lg"/>
                        </div>
                        <div className="flex flex-col justify-between w-2/3">
                            <div className={`text-center h-fit px-2 py-1 rounded text-white font-semibold  
                                ${patient.priority === 3 ? "bg-green" :
                                patient.priority === 2 ? "bg-orange" :
                                "bg-red"}
                            `}>PRIORITAS { patient.priority }</div>
                            <div className="flex flex-col items-center mt-3">
                                <Info label='Ruang' value={patient.room}/>
                                <Info className='flex flex-col items-center' label='Tanggal Masuk' value={getDateString(patient.hospitalizedDate)}/>
                            </div>
                        </div>
                    </div>
                    <div><Info className='mt-2' valueClassName="text-base tracking-normal" label="Nama" value={ patient.name }/></div>
                    <div className="mt-2 pt-2 border-t">
                        <Info className='mb-3' label='Telepon' value={patientInfo.handphone}/>
                        <Info className='mb-3' label='Email' value={patientInfo.email}/>
                        <Info className='mb-3' label='Alamat' value={patientInfo.address}/>
                    </div>
                </Card>
                <Card className={`bg-white transform-gpu drop-shadow-lg mt-4 overflow-hidden`}>
                    <div className="text-base relative z-10 py-6 px-3 font-bold text-gray-500 tracking-widest border-b shadow">RIWAYAT PARTOGRAF</div>
                    <div className="max-h-[60vh] bg-gray-100 overflow-x-hidden overflow-y-auto">
                        {renderHistories()}
                    </div>
                    <div className="relative z-10 py-6 border-b shadow rotate-180"></div>
                </Card>
                {getNotes()}
            </ContentContainer>
        </BackLayout>
    )
}
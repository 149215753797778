import { useEffect, useRef, useState } from "react"
import CloseLayout from "../../layout/CloseLayout"
import CustomHelmet from "../../layout/CustomHelmet"
import ContentContainer from "../../layout/ContentContainer"

function Content({ title, children, closeClick, isActive, hidden, setHidden, func, btnName="tambah", btnColor="bg-green", translate="translate-y-full", id="", helmetActive=undefined }) {
    const timeout = useRef(null)
    
    const [positionTop, setPositionTop] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        function handleScroll() {
            const el = ref.current
            setPositionTop(el.scrollTop)
        }

        const el = ref.current
        el.addEventListener("scroll", handleScroll)
        return () => {
            el.removeEventListener("scroll", handleScroll)
        }
    }, [])

    useEffect(() => {
        if(isActive && !hidden) clearTimeout(timeout.current)
        if(!isActive && !hidden) 
            timeout.current = setTimeout(() => {
                setHidden(true)
            }, 700)
        if(isActive) {
            setHidden(false)
            ref.current.scrollTop = 0
        }
    
    }, [isActive, hidden, setHidden])

    function getButton(){
        if(!func) return
        return (
            <div className={`
                fixed bottom-0 left-0 z-50 flex flex-col justify-end
                transition-[transform] h-screen w-screen
                ${isActive ? "duration-1000" : translate+" duration-700"} pointer-events-none
            `}>
                <input onClick={func} className={`pointer-events-auto rounded-none text-white 
                ${btnColor} px-3 pt-4 pb-6 w-full tracking-widest`} 
                type="submit" value={btnName.toUpperCase()} />
            </div>
        )
    }

    function getHelmet() {
        if ((helmetActive === undefined) || helmetActive) 
            return <CustomHelmet themeColor='#fff'/>
    }

    return (
        <>
            { getHelmet() }
            <CloseLayout id={id} title={title}
                noBorder noShadow={positionTop < 10}
                closeClick={closeClick} className={`
                    fixed top-0 left-0 z-50 flex flex-col bg-white
                    transition-[transform] duration-700 h-screen w-screen
                    shadow-2xl 
                    ${isActive ? "" : translate}
                `}>
                <ContentContainer className={`bg-white px-[1.375rem] pt-2 pb-52 overflow-y-auto h-full`} innerRef={ref}>
                    {children}
                </ContentContainer>
            </CloseLayout>
            { getButton() }
        </>
    )
}

export default function ModalWithClose({ children, isActive:inheritedIsActive, ...props }) {
    const [hidden, setHidden] = useState(true)
    const [isActive, setIsActive] = useState(false)

    const firstRender = useRef(false)
    useEffect(() => {
        if(inheritedIsActive) setHidden(false)
        setTimeout(() => setIsActive(inheritedIsActive)
            , firstRender.current ? 0 : 50)
    }, [inheritedIsActive])

    return (
        <>
            { !hidden && <Content {...props} hidden={hidden} isActive={isActive} setHidden={setHidden}>{ children }</Content> }
        </>
    )
}
const PATIENT_SET_ALL = 'patient/SET_ALL'
const PATIENT_SET = 'patient/SET'
const PATIENT_SET_INFO = 'patient/SET_INFO'
const PATIENT_SET_OPTIONS = 'patient/SET_OPTIONS'
const PATIENT_SET_PAGING = 'patient/SET_PAGING'

export {
    PATIENT_SET_ALL,
    PATIENT_SET,
    PATIENT_SET_INFO,
    PATIENT_SET_OPTIONS,
    PATIENT_SET_PAGING,
}
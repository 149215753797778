import * as types from "./types";
import states from './state'

const MAPPING = {
  [types.UNIVERSAL_TOGGLE_ADD_MODAL]: 'isAddModalShown',
  [types.UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL]: 'isAddPatientModalShown',
  [types.UNIVERSAL_TOGGLE_ADD_REPORT_MODAL]: 'isAddReportModalShown',
  [types.UNIVERSAL_TOGGLE_USER]: 'isUserModalShown',
  [types.UNIVERSAL_TOGGLE_NOTIFICATION_MODAL]: 'isNotificationModalShown',
  [types.UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL]: 'isAnnouncementModalShown',
}

const reducer = (state = states, action) => {
  const newState = {...state};
  if(types.UNIVERSAL_PUSH_CONFIRM_DIALOG === action.type)
    newState.confirmationDialogs = [action.payload].concat(newState.confirmationDialogs)
  else if(types.UNIVERSAL_REMOVE_CONFIRM_DIALOG === action.type)
    newState.confirmationDialogs = state.confirmationDialogs.filter(x => x.id !== action.payload)
  else newState[MAPPING[action.type]] = !newState[MAPPING[action.type]]
  return newState
}

export * from './actions'
export default reducer
import { Helmet } from "react-helmet";

export default function CustomHelmet({ title="Aplikasi Partograf", themeColor='#ff99cc' }) {
    return (
        <Helmet>
            <meta name="viewport" content={`width=device-width, initial-scale=1, maximum-scale=1`}/>
            <meta name="theme-color" content={themeColor} />
            <title>{title}</title>
        </Helmet>
    )
}
import { useEffect, useState } from "react"
import TopBar from "../layout/TopBar"
import ContentContainer from "../layout/ContentContainer"
import { DocumentPlusIcon } from '@heroicons/react/24/outline'
import { ArrowUpIcon, ArrowDownIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { toggleModal } from "../store/modules/universal"
import { useDispatch, useSelector } from "react-redux"
import Card from "../layout/Card"
import ReportGrid from "../components/report/ReportGrid"
import HorizontalSeparator from "../layout/HorizontalSeparator"
import InputDropdown from "../components/template/InputDropdown"
import { fetchOptions, fetchPatient } from "../store/modules/patient"
import { fetchReports } from "../store/modules/report"
import { getTime, patientDropdownOption } from "../utils/common-function"
import ShadedDiv from "../components/template/ShadedDiv"
import DotDiv from "../components/template/DotDiv"
import { UNIVERSAL_TOGGLE_ADD_REPORT_MODAL } from "../store/modules/universal/types"

const defaultGridState = () => {
    return {
        contents: [],
        lines: []
    }
}
const titleClass = "mb-1.5 text-primary font-semibold"
const separatorClass = "mt-3" 
const reportTypeOptions = [{value: 1, text: 'Partograf'}, {value: 2, text: 'Catatan Persalinan'}, {value: 3, text: 'Semua'}]

export default function Reports(){
    const dispatch = useDispatch()
    const searchOptions = useSelector(state => state['patientReducer'].options)
    const reports = useSelector(state => state['reportReducer'].reports)
    const reportPatientId = useSelector(state => state['reportReducer'].reportPatientId)
    const [patientId, setPatientId] = useState(reportPatientId < 0 ? '' : reportPatientId)
    const [patientDropdownOpen, setPatientDropdownOpen] = useState(false)
    const [typeOpen, setTypeOpen] = useState(false)
    const [type, setType] = useState(1)

    const [fetalHeartRate, setFetalHeartRate] = useState(defaultGridState())
    const [cervixAndHead, setCervixAndHead] = useState(defaultGridState())
    const [pulse, setPulse] = useState(defaultGridState())
    
    const [contraction, setContraction] = useState([])
    const [amnioticFludAndInfiltration, setAmnioticFludAndInfiltration] = useState([])
    const [time, setTime] = useState([])
    const [headNumber, setHeadNumber] = useState([])
    const [medicationsAndIVFluid, setMedicationsAndIVFluid] = useState([])
    const [oxytocin, setOxytocin] = useState([])
    const [temperature, setTemperature] = useState([])
    const [urine, setUrine] = useState([])

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(patientId < 1) return
        setShow(false)
        dispatch(fetchReports(patientId)).finally(() => {
            setTimeout(() => setShow(true), 1250)
        })
        dispatch(fetchPatient(patientId))
    
    }, [dispatch, patientId])

    useEffect(() => {
        dispatch(fetchOptions({search: ''}))
    }, [dispatch])
    
    useEffect(() => {
        function convertReportAttribute(value, multiplier=1, startValue=1) {
            if(!value) return { y: undefined, margin: undefined }
            const normalize = value-startValue
            const modulo = normalize % multiplier
            const y = Math.floor(normalize / multiplier)
            const margin = modulo / multiplier
            return { y: y, margin: margin }
        }
    
        function getReportRender(name, color="#ec3b83") {
            if(name === "dot")
                return <div className="absolute bottom-0 w-1.5 h-1.5 rounded-full -translate-x-1/2 translate-y-1/2" style={{backgroundColor: color}}/>
            if(name === "dotDiv") return <DotDiv color={color}/>
            if(name === "shadedDiv") return <ShadedDiv color={color}/>
            if(name === "arrowUp") return <div className="absolute top-full -left-2 w-4 h-4 translate-x-[0.5px] -translate-y-1/4" style={{color}}><ArrowUpIcon className="stroke-2" style={{stroke: color}}/></div>
            if(name === "arrowDown") return <div className="absolute bottom-0 -left-2 w-4 h-4 translate-x-[0.5px] translate-y-1/4" style={{color}}><ArrowDownIcon className="stroke-2" style={{stroke: color}}/></div>
            if(name === "circle") return <div className="absolute bottom-0 w-2.5 h-2.5 border-[2px] rounded-full -translate-x-1/2 translate-y-1/2"  style={{borderColor: color}}></div>
            if(name === "xmark") return <div className="absolute bottom-0 w-4 h-4 -translate-x-1/2 translate-y-1/2" style={{color}}><XMarkIcon className="stroke-2" style={{stroke: color}}/></div>
            return <div className="w-full h-full" style={{backgroundColor: color}}></div>
        }
    
        function processBasicAttribute(content, lines, { x: targetX, y: targetY, margin: targetMargin }, { x, y, margin }, renderName="dot", color='#ec3b83') {
            if(y === undefined) return
            content.push({
                on: {x:x, y:y},
                margin: margin, render: getReportRender(renderName, color)
            })
            if(targetY === undefined) return
            lines.push({
                from: {x: targetX, y:targetY},
                to: { x, y }, color,
                marginFrom: targetMargin,
                marginTo: margin
            })
        }
    
        function processContraction(contractionContent, { x, times, duration }) {
            for(let i = 0; i < times; i++)
                contractionContent.push({ 
                    on: {x: x, y: i},
                    render: duration < 20 ? getReportRender("dotDiv") :
                    duration > 40 ? getReportRender("") : getReportRender("shadedDiv")
                })
        }
    
        function processCombineAttribute(content, { x, ...map }) {
            let y = -1
            for(let key of Object.keys(map)){
                y++
                if(!map[key]) continue
                content.push({ on: {x:x, y:y}, render: 
                    <div className={` text-primary-dark
                        ${key === 'time' ? 'tracking-tighter' : ''} 
                        w-full h-full flex justify-center items-center`}>
                        {map[key]}
                    </div>
                })
            }
        }
    
        function getMarginX() {
            const reportLength = reports.length
            if(reportLength === 0) return 0
            if(!reports[reportLength - 1].cervixOpening) return 0
            if(reports[reportLength - 1].cervixOpening < 4) return 0
            return (reports[reportLength - 1].cervixOpening - 4) * 2
        }
    
        function processReports() {
            const marginX = getMarginX()
            const fetalHeartRateContent = []
            const fetalHeartRateLines = []
            let prevFetalHeartRate = {}
    
            const cervixAndHeadContent = []
            const cervixAndHeadLines = []
            let prevCervix = {}
            let prevHead = {}
    
            const contractionContent = []
            
            const pulseContent = []
            const pulseLines = []
            let prevPulse = {}
            let prevBP = {}
    
            const aFAIContent = []
            const timeContent = []
            const mAIVFContent = []
            const oxytocinContent = []
            const temperatureContent = []
            const urineContent = []
            
            for(let x = reports.length - 1; x >= 0; x--){
                const report = reports[x] 
                const negateX = reports.length - x - 1 + marginX
    
                const evalFetalHeartRate = convertReportAttribute(report.fetalHeartRate, 10, 80)
                processBasicAttribute(fetalHeartRateContent, fetalHeartRateLines, prevFetalHeartRate, { x:negateX, ...evalFetalHeartRate })
                prevFetalHeartRate = { x:negateX, ...evalFetalHeartRate }
    
                const evalCervix = convertReportAttribute(report.cervixOpening, 1, 0)
                processBasicAttribute(cervixAndHeadContent, cervixAndHeadLines, prevCervix, { x:negateX, ...evalCervix }, "xmark")
                if(evalCervix.y) prevCervix = { x:negateX, ...evalCervix }
    
                const evalHead = convertReportAttribute(report.headDrop, 1, 0)
                processBasicAttribute(cervixAndHeadContent, cervixAndHeadLines, prevHead, { x:negateX, ...evalHead }, "circle")
                if(evalHead.y) prevHead = { x:negateX, ...evalHead }
                
                processContraction(contractionContent, { x:negateX, times:report.contractionTimes, duration:report.contractionDuration })
    
                const evalPulse = convertReportAttribute(report.pulse, 10, 60)
                processBasicAttribute(pulseContent, pulseLines, prevPulse, { x:negateX, ...evalPulse })
                prevPulse = { x:negateX, ...evalPulse }
    
                const evalBloodPressureMin = convertReportAttribute(report.bloodPressureMin, 10, 60)
                processBasicAttribute(pulseContent, pulseLines, prevBP, { x:negateX, ...evalBloodPressureMin }, "arrowDown")
                prevBP = { x:negateX, ...evalBloodPressureMin }
                const evalBloodPressureMax = convertReportAttribute(report.bloodPressureMax, 10, 60)
                processBasicAttribute(pulseContent, pulseLines, prevBP, { x:negateX, ...evalBloodPressureMax }, "arrowUp")
    
                processCombineAttribute(aFAIContent, { x:negateX , infiltration:report.infiltration, amnioticFluid:report.amnioticFluid })
                if(x % 2 === 1 || (reports.length % 2 === 1 && x === 0))
                    processCombineAttribute(timeContent, { x:Math.floor(negateX/2) , time:getTime(report.reportedDate) })
                processCombineAttribute(mAIVFContent, { x:Math.floor(negateX/2) , medicationsAndIVFluid:report.medicationsAndIVFluid })
                processCombineAttribute(oxytocinContent, { x:negateX, oxytocinUI:report.oxytocinUI, oxytocinDrops:report.oxytocinDrops })
                processCombineAttribute(temperatureContent, { x:Math.floor(negateX/2) , temperature:report.temperature })
                processCombineAttribute(urineContent, { x:Math.floor(negateX/2), volume:report.urineVolume, urineAseton:report.urineAseton, urineProtein:report.urineProtein })
            }

            const headNumberContent = []
            cervixAndHeadLines.push({from: {x: 0, y: 4}, to: {x: 12, y: 10}, label: 'WASPADA', color: "#555"})
            cervixAndHeadLines.push({from: {x: 8, y: 4},to: {x: 20, y: 10}, label: 'BERTINDAK', color: "#555"})

            setFetalHeartRate({ contents: fetalHeartRateContent, lines: fetalHeartRateLines })
            setCervixAndHead({ contents: cervixAndHeadContent, lines: cervixAndHeadLines })
            setPulse({ contents: pulseContent, lines: pulseLines })
            
            setContraction(contractionContent)
            setAmnioticFludAndInfiltration(aFAIContent)
            setHeadNumber(headNumberContent)
            setTime(timeContent)
            setMedicationsAndIVFluid(mAIVFContent)
            setOxytocin(oxytocinContent)
            setTemperature(temperatureContent)
            setUrine(urineContent)
        }

        processReports(reports)
    }, [reports])

    function handleChange(e) {
        const { value } = e.target
        setPatientId(value)
    }

    function handleSearchChange(newValue) {
        if(newValue) dispatch(fetchOptions({search: newValue}))
    }

    function localToggleAddModal() {
        dispatch(toggleModal(UNIVERSAL_TOGGLE_ADD_REPORT_MODAL))
    }

    return (
        <>
            <div className="sticky -top-px z-40">
                <TopBar className="pb-0 bg-primary flex text-xl items-end justify-between font-bold text-white tracking-wide" noShadow noBorder>
                    <div>Laporan</div>
                </TopBar>
                <div className="flex justify-end px-4 bg-primary">
                    <div className={`bg-white w-36 text-xs text-gray-500 mb-1
                        shadow
                        ${typeOpen ? 'rounded-t-sm' : 'rounded-sm'}
                    `}>
                        <InputDropdown alwaysShow
                        onChange={({target}) => setType(target.value)}  className="mx-2 py-0.5"
                        value={type} placeholder="Tipe Laporan" setOpen={setTypeOpen}
                        options={reportTypeOptions}/>
                    </div>
                </div>
                <div className="px-4 relative">
                    <div className="absolute -top-px left-0 h-1/2 w-full bg-primary shadow-lg"/>
                    <Card className="bg-white flex w-full relative z-10" noBorder>
                        <div className={`flex-grow transition-[width] bg-white text-sm ${patientDropdownOpen ? 'rounded-t' : 'rounded' }`}>
                            <InputDropdown 
                                setOpen={setPatientDropdownOpen} className='mx-3' 
                                withSearch handleSearchChange={handleSearchChange}
                                options={searchOptions} customOption={patientDropdownOption}
                                value={patientId} onChange={handleChange} 
                                name="patientSearch" id="patient" placeholder="Pasien"
                            />
                        </div>
                        <div onClick={localToggleAddModal} className={`${ patientDropdownOpen ? 'w-0' : 'w-12 border-l' } overflow-hidden 
                            relative transition-[width] h-12 text-primary select-none 
                            cursor-pointer`}>
                            <div className="h-12 w-12 absolute top-0 left-0 flex items-center justify-center">
                                <DocumentPlusIcon className="w-7 h-7"/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <ContentContainer className="">
                <Card className={'bg-white drop-shadow-lg p-4 text-sm'}>
                    <div className="">
                        <HorizontalSeparator title="Denyut Jantung Janin" className={titleClass}/>
                        <ReportGrid show={show} setShow={setShow} lines={fetalHeartRate.lines} contents={fetalHeartRate.contents} width="24" height="12" withLabel labelStart="80" labelMultiplier="10" plusOneLabel/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Air Ketuban & Penyusupan" className={titleClass}/>
                        <ReportGrid contents={amnioticFludAndInfiltration} show={show} setShow={setShow} width="24" height="2"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Pembukaan Serviks & Turunnya Kepala" className={titleClass}/>
                        <ReportGrid contents={cervixAndHead.contents} lines={cervixAndHead.lines} show={show} setShow={setShow} width="24" height="10" defaultLines withLabel plusOneLabel noBottomRounded/>
                        <ReportGrid contents={headNumber} show={show} setShow={setShow} width="12" height="1" noRounded noTopBorder withNumber/>
                        <ReportGrid contents={time} show={show} setShow={setShow} width="12" height="1" noTopBorder heightMultiplier="2"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Kontraksi Setiap 10 Menit" className={titleClass}/>
                        <ReportGrid show={show} setShow={setShow} contents={contraction} width="24" height="5" withLabel labelStart="1"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Oksitosin" className={titleClass}/>
                        <ReportGrid contents={oxytocin} show={show} setShow={setShow} width="24" height="2"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Obat & Cairan IV" className={titleClass}/>
                        <ReportGrid contents={medicationsAndIVFluid} show={show} setShow={setShow} width="12" height="1" heightMultiplier="2"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Nadi & Tekanan Darah" className={titleClass}/>
                        <ReportGrid show={show} setShow={setShow} contents={pulse.contents} lines={pulse.lines} width="24" height="12" withLabel labelStart="60" labelMultiplier="10" plusOneLabel/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Temperatur &deg;C" className={titleClass}/>
                        <ReportGrid contents={temperature} show={show} setShow={setShow} width="12" height="1"/>
                    </div>
                    <div className={separatorClass}>
                        <HorizontalSeparator title="Urine" className={titleClass}/>
                        <ReportGrid contents={urine} show={show} setShow={setShow} width="12" height="3"/>
                    </div>
                </Card>
            </ContentContainer>
        </>
    )
}
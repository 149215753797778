import { useEffect, useState } from "react";
import CustomInputCheckbox from "../components/template/CustomInputCheckbox";
import DivLeftIcon from "../components/template/DivLeftIcon";
import { useSelector, useDispatch } from "react-redux"
import { userLogin } from '@/store/modules/user'
import { useNavigate } from 'react-router-dom';
import { userFetch } from "@/store/modules/user";
import Cookies from "js-cookie";
import CustomInput from "../components/template/CustomInput";
import { ClipboardDocumentListIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import CustomHelmet from "../layout/CustomHelmet";
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/solid'
import TypingAnimation from "../components/template/TypingAnimation";

const textArr = [
    "Silahkan masuk kedalam akun anda",
    "Selamat menggunakan",
    "Jangan lupa jaga kesehatan"
]

export default function Login() {
    const user = useSelector((state) => state['userReducer'].user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        document.body.className = 'bg-primary'
    }, [])
    
    if(user === '' && Cookies.get('auth')) {
        dispatch(userFetch()).then(() => {
            navigate('/')
        })
    }

    const [form, setForm] = useState({
        username: '',
        password: '',
        rememberMe: false
    })

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target
        setForm(state => ({
            ...state,
            [name]: type === 'checkbox' ? checked : value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(userLogin(form)).then(() => {
            navigate('/')
        })
    }

    return(
        <div className="h-screen w-full flex flex-col">
            <CustomHelmet title="Halaman Masuk"/>
            <div className="relative h-1/5 overflow-hidden text-white-background">
                <ClipboardDocumentListIcon className="-bottom-[30%] right-0 absolute h-[125%] rotate-12"/>
                <DocumentDuplicateIcon className="absolute h-2/3 -rotate-12 bottom-[10%] left-[5%]"/>
            </div>
            <form className="h-4/5 w-full p-12 rounded-t-2xl bg-white flex flex-col" onSubmit={handleSubmit}>
                <div className="flex flex-col items-center">
                    <div className="text-7xl font-bold text-gray-500">Halo!</div>
                    <div className="text-sm h-4 text-gray-400 flex items-center">
                        <TypingAnimation textArr={textArr} />
                    </div>
                </div>
                <div className="flex-grow flex flex-col justify-evenly">
                    <div>
                        <DivLeftIcon border shadow yCentered icon={<UserIcon className="w-6 h-6"/>}>
                            <CustomInput type="text" placeholder="Email" className="px-1 py-1 text-sm" value={form.username} onChange={handleChange} name="username"/>
                        </DivLeftIcon>
                        <DivLeftIcon border shadow yCentered className="mt-3" icon={<LockClosedIcon className="w-6 h-6"/>}>
                            <CustomInput type="password" placeholder="Kata sandi" className="px-1 py-1 text-sm" value={form.password} onChange={handleChange} name="password" />
                        </DivLeftIcon>
                        <CustomInputCheckbox placeholder="Masuk secara otomatis" className="mt-2 text-xs" value={form.rememberMe} onChange={handleChange} name="rememberMe" id="rememberMe"/>
                    </div>
                    <div>
                        <input className="rounded bg-green cursor-pointer text-white shadow-inner tracking-widest w-full py-2" type="submit" value="MASUK" />
                        <div className="flex justify-center text-xs text-gray-400 mt-1">
                            Belum ada akun?
                            <span className="ml-2 cursor-pointer text-primary underline">Hubungi kami</span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
import React, { useEffect, useMemo, useState } from "react";

export default function Input({ className = '', id = '', name = '', value = '', onChange, onBlur, placeholder, type, placeholderMove, border, innerRef, textAlign, required, ...props }) {
  const left = (!className.split(' ').find((c) => c.startsWith("px-"))) ? 0 : Number(className.split(' ').find((c) => c.startsWith("px-")).replace("px-", ""));
  const top = (!className.split(' ').find((c) => c.startsWith("py-"))) ? 0 : Number(className.split(' ').find((c) => c.startsWith("py-")).replace("py-", ""));
  const cursor = (className.split(' ').find(c => c.startsWith("cursor")))

  const defaultStyle = useMemo(() => {
    return {
      left: 'calc('+ left +' * .25rem)',
    }  
  }, [left])
  const [spanStyle, setSpanStyle] = useState(defaultStyle)

  useEffect(() => {
      if(!placeholderMove) return
      setSpanStyle(value === '' ? defaultStyle : {
          top: 'calc('+ (top + 5) + ' * .25rem * -1)',
          left: '0'
      })
  }, [value, placeholderMove, defaultStyle, top])

  return (
    <div className={`relative w-full h-full flex items-center
        ${border ? "border-b border-primary" : ""}
        ${className}`} id={id}>
        <input ref={innerRef} id={name} required={!!required} className={`bg-transparent w-full outline-none ${cursor} ${textAlign}`} type={type} name={name} value={value} onChange={onChange} onBlur={onBlur} {...props}/>
      <label htmlFor={name} className={`
        absolute duration-150 ease-in-out top-0 h-full flex items-center 
        select-none pointer-events-none 
        ${value === '' ? 'text-gray-400' : 'text-primary'}
        ${!placeholderMove && value !== '' ? "opacity-0" : ""}
        ${placeholderMove ? '' : 'transition-none'}
      `} style={spanStyle}
      >{placeholder}</label>
    </div>
  );
}
import user from './modules/user'
import patient from './modules/patient'
import report from './modules/report'
import homepage from './modules/homepage'

const data = [
    ...user,
    ...patient,
    ...report,
    ...homepage,
]

export default data
import httpApi from '@/utils/http-api'
import api from '../config'

const url = api.report
const obj = {
    getReports(id) {
        const path = url.getReports(id)
        return httpApi.getRequest(path)
    },
    getNotes(id) {
        const path = url.getNotes(id)
        return httpApi.getRequest(path)
    }
}

export default obj
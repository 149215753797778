import { useEffect, useRef } from "react"

export default function ReportLine({ className="", flip, lineWidth=2, color="#000", width, height, shaded }) {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        if(!canvas) return

        function drawCanvas(){
            const ctx = canvas.getContext('2d')
            const scale = Math.ceil(window.devicePixelRatio)
            canvas.width = Math.ceil(scale * width)
            canvas.height = Math.ceil(scale * height)

            const canvasWidth = canvas.width
            const canvasHeight = canvas.height
            ctx.scale(scale, scale)
    
            ctx.strokeStyle = color
            ctx.clearRect(0,0,canvasWidth / scale,canvasHeight / scale)
            ctx.lineWidth = lineWidth
            
            ctx.beginPath()
            if(flip){
                ctx.moveTo(0.5, 0.5)
                ctx.lineTo(canvasWidth / scale - 0.5, canvasHeight / scale - 0.5)
            } else {
                ctx.moveTo(0.5, canvasHeight / scale - 0.5)
                ctx.lineTo(canvasWidth / scale - 0.5, 0.5)
            }
            if(shaded) {
                const deltaX = Math.ceil(canvasWidth / scale / 3)
                const deltaY = Math.ceil(canvasHeight / scale / 3)
                for(let i = 1; i <= 2; i++){
                    ctx.moveTo(0.5, canvasHeight / scale - i*deltaY - 0.5)
                    ctx.lineTo(canvasWidth / scale - i*deltaX - 0.5, 0.5)
                }
                for(let i = 1; i <= 2; i++){
                    ctx.moveTo(0.5, canvasHeight / scale - -1*i*deltaY - 0.5)
                    ctx.lineTo(canvasWidth / scale - -1*i*deltaX - 0.5, 0.5)
                }
            }
            ctx.stroke()
        }
        
        drawCanvas()
    }, [flip, color, lineWidth, width, height, shaded])
    return (
        <canvas className={`w-full h-full ${className}`} width={width} height={height} ref={canvasRef}/>
    )
}
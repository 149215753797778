import * as types from './types'
import api from '../../../api/controller/report'

const fetchReports = (patientId) => {
    return async (dispatch) => {
        const { data, status } = await api.getReports(patientId)
        dispatch({type: types.REPORT_SET, payload: data})
        if(status === 200) dispatch({type: types.REPORT_SET_PATIENT, payload: patientId})
        else dispatch({type: types.REPORT_SET_PATIENT, payload: -1})
    }
}

const setReportPatientId = (patientId) => {
    return async (dispatch) => {
        dispatch({type: types.REPORT_SET_PATIENT, payload: patientId})
    }
}

const fetchNotes = (patientId) => {
    return async (dispatch) => {
        const { data } = await api.getNotes(patientId)
        dispatch({type: types.REPORT_SET_NOTE, payload: data})
    }
}

export {
    fetchReports,
    setReportPatientId,
    fetchNotes,
}
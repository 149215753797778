export default function DivLeftIcon({ children, icon, className='', shadow, border, yCentered, xCentered }) {
    return (
        <div className={"rounded overflow-hidden flex w-full " + 
        (shadow ? 'shadow' : '') + " " +
        (border ? 'border border-primary' : '') + " " +
        className
        }>
            <div className="bg-primary text-white w-10 flex items-center justify-center h-10">
                { icon }
            </div>
            <div className={"flex-grow px-3 flex " + 
                (yCentered ? 'items-center' : '') + " " +
                (xCentered ? 'justify-center' : '') + " "
            }>
                { children }
            </div>
        </div>
    )
}
import { UserPlusIcon, DocumentPlusIcon } from '@heroicons/react/24/outline'

export default function AddModal({ isActive, toggleModal, togglePatientModal, toggleReportModal }){

    const handleMaskClick = () => {
        if(isActive) toggleModal()
    }

    const handleNewPatient = () => {
        togglePatientModal()
        handleMaskClick()
    }

    const handleNewData = () => {
        toggleReportModal()
        handleMaskClick()
    }

    return (
        <div className={`absolute -z-10 bottom-0 left-0 w-full pointer-events-none
            transition-[transform] duration-300 px-5 py-3 ease-in-out justify-evenly flex items-end 
            ${isActive ? "" : "translate-y-full"}`} 
            >
            <div onClick={handleNewPatient} 
            className="w-full h-fit text-center py-2 px-1 mr-2 flex items-center justify-evenly
            bg-primary select-none shadow-lg drop-shadow pointer-events-auto transform-gpu
            rounded-md text-white">
                <UserPlusIcon className='w-8 h-8'/>
                <div className='text-center tracking-wider text-xs'>TAMBAH<br/>PASIEN BARU</div>
            </div>
            <div onClick={handleNewData} 
            className="w-full h-fit text-center py-2 px-1 ml-2 flex items-center justify-evenly
            bg-primary select-none rounded-md shadow-lg drop-shadow pointer-events-auto
            text-white transform-gpu">
                <DocumentPlusIcon className='w-8 h-8'/>
                <div className='text-xs'>TAMBAH<br/>DATA BARU</div>
            </div>
        </div>
    )
}
export default function DotDiv({ color="#15f" }) {

    function renderHorizontal(n) {
        const arr = []
        for(let i = 0; i < n; i++)
            arr.push(<div key={i} className="h-[2px] w-[2px] rounded-full" style={{backgroundColor: color}}></div>)
        return <div className="flex justify-evenly">{arr}</div>
    }

    return (
        <div className="w-full h-full flex flex-col justify-evenly p-px">
            {renderHorizontal(4)}
            {renderHorizontal(3)}
            {renderHorizontal(4)}
        </div>
    )
}
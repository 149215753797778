import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import CustomInput from "./CustomInput";

export default function InputDropdown({ 
    className = '', id = '', name = '', value = undefined, maxHeight="max-h-52", options = [], 
    placeholderMove, onChange, setOpen: inheritedSetOpen, withSearch, valueCenter,
    placeholder="", withBorder, labelColor="text-primary", alwaysShow, valueIsSearch,
    handleSearchChange: inheritedSearchChange, customOption, label: inheritedLabel, 
    noRevert, autoChange, border, rounded="rounded", ...props 
}) {
    const left = !className.split(' ').find((c) => c.startsWith("px-")) ? 0 : Number(className.split(' ').find((c) => c.startsWith("px-")).replace("px-", ""));
    const top = !className.split(' ').find((c) => c.startsWith("py-")) ? 0 : Number(className.split(' ').find((c) => c.startsWith("py-")).replace("py-", ""));

    const searchBar = useRef(null)
    const mounted = useRef(false)

    const [isOpen, setOpen] = useState(false)
    const [label, setLabel] = useState(inheritedLabel ? inheritedLabel : '')
    const [searchText, setSearchText] = useState('')

    const defaultStyle = useMemo(() => {
        return {
            left: 'calc('+ left +' * .25rem)',
        }
    }, [left])

    const [spanStyle, setSpanStyle] = useState(defaultStyle)

    const getLabelCondition = useCallback(() => {
        if(withSearch) return (value && searchText) || searchText
        return value
    }, [searchText, value, withSearch])

    const fetchLabelFromValue = useRef(false)
    useEffect(() => {
        if(value === undefined && !isOpen) {
            setLabel('')
            setSearchText('')
        }
        if(value === undefined) return
        if(fetchLabelFromValue.current && isOpen) return
        let findText = options.find(i => i.value === value)
        if(findText === undefined) return
        findText = findText.text
        setSearchText(findText)
        setLabel(findText)
        
        fetchLabelFromValue.current = true
    }, [value, options, isOpen, name])
  
    useEffect(() => {
        if(!placeholderMove) return
        setSpanStyle(getLabelCondition() ? {
            top: 'calc('+ (top + 5) + ' * .25rem * -1)',
            left: '-0.5rem'
        } : defaultStyle)
    
    }, [value, searchText, placeholderMove, defaultStyle, getLabelCondition, top])

    useEffect(() => {
        if(inheritedSetOpen) inheritedSetOpen(isOpen)
        if(isOpen) return
        if(searchBar.current) searchBar.current.blur()
        if(!noRevert && mounted.current) setSearchText(label)
        if(!mounted.current) mounted.current = true
    }, [isOpen, inheritedSetOpen, label, mounted, noRevert])

    function handleOptionClick(i) {
        const e = {target:{
            name,
            value: i.value,
            type:'custom-dropdown',
            label: i.text
        }}
        if(onChange) onChange(e)
        setOpen(false)
        setLabel(i.text)
        setSearchText(i.text)
        if(!withSearch) return 
        searchBar.current.blur()
        handleSearchChange(i.text)
    }

    function handleSearchChange(value) {
        setSearchText(value)
        if(inheritedSearchChange)
            inheritedSearchChange(value)
    }

    function renderOption(o) {
        if(customOption) return customOption(o, className)
        return (
            <div className={`${className} flex border-b`}>
                <div className={`${valueCenter ? 'text-center' : ''} flex-grow`}>{o.text}</div>
                <div className="w-4"></div>
            </div>
        )
    }

    function renderOptions() {
        return options.filter(o => o.value !== value).map((o, idx) => {
            return (
                <div className={`hover:bg-black hover:bg-opacity-20`} 
                    onClick={() => handleOptionClick(o)} key={idx}
                >
                    {renderOption(o)}
                </div>
            )
        })
    }

    function toggleOpen() {
        if(!isOpen && withSearch && searchBar.current)
            searchBar.current.focus()
        setOpen(!isOpen)
    }

    function getSearchBar() {
        if(!withSearch) return <div className={`${valueCenter ? 'text-center' : ''} text-black`}>{label}&nbsp;</div>
        return (
                <CustomInput type="text"
                innerRef={searchBar} className="cursor-pointer" 
                onChange={(e) => handleSearchChange(e.target.value)} 
                value={searchText}/>
        )
    }

    return (
        <>
            {/* {value ? 'true' : 'false'},{isOpen},{searchText ? 'true' : 'false'},{label} */}
            <div className={`${isOpen ? '' : 'hidden'} fixed z-20 top-0 left-0 w-screen h-screen`} onClick={() => setOpen(false)}/>
            <div className={`group/dropdown z-20 relative w-full h-full flex flex-col cursor-pointer`} id={id}>
                <div onClick={toggleOpen} className={`flex w-full h-full outline-none items-center`} type={props.type} name={name}>
                    <div className={`flex w-full ${className}`}>
                        <div className={`flex-grow select-none`}>
                            {getSearchBar()}
                        </div>
                        <div className={`group-hover/dropdown:opacity-100 ${isOpen || alwaysShow ? 'opacity-100' : 'opacity-0'} 
                        duration-150 transition-[opacity,transform] w-4 h-4 ${isOpen ? 'rotate-180' : ''}
                        `}><ChevronDownIcon/></div>
                    </div>
                </div>
                <div className={`absolute left-0 top-0 w-full h-full pointer-events-none ${border ? (isOpen ? ' border-x border-t rounded-t' : 'rounded border') : ''}`}/>
                <div className={`${maxHeight} ${isOpen ? '' : 'hidden'}  
                overflow-y-auto flex flex-col text-gray-500 absolute z-10 top-full 
                left-0 w-full bg-white rounded-b shadow-lg`}>
                    <div className="relative pb-1">
                        <div className={`absolute left-0 top-0 w-full h-full rounded-b pointer-events-none ${border ? 'border-x border-b' : ''}`}/>
                        <div className={`${className} pt-0 pb-0 border-t`}></div>
                        {renderOptions()}
                    </div>
                </div>
                <span className={`
                    absolute h-full top-0 flex items-center duration-150 ease-in-out 
                    select-none pointer-events-none ${className}
                    ${getLabelCondition() ? labelColor : 'text-gray-400'}
                    ${!placeholderMove && ((!withSearch && value !== undefined) || (withSearch && searchText)) ? "opacity-0" : ""}
                    ${placeholderMove ? '' : 'transition-none'}
                `} style={spanStyle}
                >{placeholder}</span>
            </div>
        </>
    )
}
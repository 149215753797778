import * as types from './types'
import api from '@/api/controller/user'
import Cookies from 'js-cookie'

const userLogin = (form) => {
    return async (dispatch) => {
        const { data, status } = await api.userLogin(form)
        if(status === 200 && form.rememberMe) Cookies.set('auth', data.username)
        dispatch({type: types.USER_LOGIN, payload: data})
    }
}
const userFetch = () => {
    return async (dispatch) => {
        const { data, status } = await api.userFetch()
        if(status === 200) dispatch({type: types.USER_LOGIN, payload: data})
        else {
            Cookies.remove('auth')
            throw new Error('failed fetch by cookie')
        }
    }
}
const userLogout = () => {
    return async (dispatch) => {
        const { status } = await api.userLogout()
        if(status !== 200) return
        Cookies.remove('auth') 
        dispatch({type: types.USER_LOGOUT})
    }
}

export {
    userLogin,
    userFetch,
    userLogout
}
import { CheckIcon } from '@heroicons/react/24/outline'

export default function CustomInputCheckbox({ className='', id='', name='', value='', onChange, placeholder }) {
    
    return (
        <div className={"flex " + className}>
            <input className="hidden outline-none mr-2 border-primary" type="checkbox" name={name} value={value} onChange={onChange} id={id}/>
            <label htmlFor={id} className={`flex items-center select-none
                ${!value ? "text-gray-400" : 'text-primary'}
            `}>
                <div className={`flex justify-center items-center w-4 h-4 border-2 rounded ${!value ? '' : 'border-primary bg-primary' }`}>
                    <CheckIcon className={`absolute text-white ${!value ? 'hidden' : ''} w-4 h-4`}/>
                </div>
                <span className='ml-2'>{placeholder}</span>
            </label>
        </div>
    )
}
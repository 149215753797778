import * as types from "./types";
import states from './state'

const MAPPING = {
  [types.PATIENT_SET_ALL]: 'patients',
  [types.PATIENT_SET]: 'patient',
  [types.PATIENT_SET_INFO]: 'patientInfo',
  [types.PATIENT_SET_OPTIONS]: 'options',
  [types.PATIENT_SET_PAGING]: 'paging'
}

const reducer = (state = states, action) => {
  const newState = {...state};
  newState[MAPPING[action.type]] = action.payload
  return newState
}

export * from './actions'
export default reducer
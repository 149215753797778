const UNIVERSAL_TOGGLE_ADD_MODAL = 'universal/ADD_MODAL'
const UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL = 'universal/ADD_PATIENT_MODAL'
const UNIVERSAL_TOGGLE_ADD_REPORT_MODAL = 'universal/ADD_REPORT_MODAL'
const UNIVERSAL_TOGGLE_USER = 'universal/USER'
const UNIVERSAL_TOGGLE_NOTIFICATION_MODAL = 'universal/NOTIFICATION_MODAL'
const UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL = 'universal/ANNOUNCEMENT_MODAL'
const UNIVERSAL_PUSH_CONFIRM_DIALOG = 'universal/PUSH_CONFIRM_DIALOG'
const UNIVERSAL_REMOVE_CONFIRM_DIALOG = 'universal/REMOVE_CONFIRM_DIALOG'

export {
    UNIVERSAL_TOGGLE_ADD_MODAL,
    UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL,
    UNIVERSAL_TOGGLE_ADD_REPORT_MODAL,
    UNIVERSAL_TOGGLE_USER,
    UNIVERSAL_TOGGLE_NOTIFICATION_MODAL,
    UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL,
    UNIVERSAL_PUSH_CONFIRM_DIALOG,
    UNIVERSAL_REMOVE_CONFIRM_DIALOG,
}
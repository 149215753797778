import { useEffect, useState } from "react"

export default function TypingAnimation({ textArr=[""], characterDelay=50, sentenceDelay=5000, barColor="bg-gray-400" }){
    const [text, setText] = useState({idx: 0, length: 0})

    useEffect(() => {
        setTimeout(() => {
            if(text.length > textArr[text.idx].length){
               
              setTimeout(() => {
                setText({
                  length: 0, 
                  idx: text.idx + 1 >= textArr.length ? 0 : text.idx + 1
                })
              }, sentenceDelay)
            }
            else setText({length: text.length + 1, idx: text.idx})
        }, characterDelay)
    
    }, [text, characterDelay, sentenceDelay, textArr])

    return (
        <>
            <div>{textArr[text.idx].slice(0, text.length)}</div>
            <div className={`w-0.5 h-full ${barColor} ${text.length > textArr[text.idx].length ? 'animate-blink' : ''}`}/>
        </>
    )
}
import { useEffect, useRef, useState } from "react"
import ReportLine from "../report/ReportLine"

export default function ShadedDiv({ color="#15f" }) {
    const el = useRef(null)
    const [cell, setCell] = useState({width: 0, style: { border: '1px solid ' + color, width: '0px' }})
    useEffect(() => {
        if(!el.current) return
        function calculate() {
            const w = el.current.offsetWidth
            const h = el.current.offsetHeight
            setCell({
                width: w,
                height: h
            })
        }

        calculate()
        window.addEventListener('resize', calculate)
        return () => {
            window.removeEventListener('resize', calculate)
        }
    }, [])

    return (
        <div className="relative h-full w-full flex items-center overflow-hidden pointer-events-none" ref={el}>
            <ReportLine className="absolute" width={cell.width} height={cell.height} color={color} shaded/>
        </div>
    )
}
import ContentContainer from "../../layout/ContentContainer";
import ModalWithClose from "../template/ModalWithClose";

export default function Announcements({ isActive, toggleModal }){


    return (
        <ModalWithClose title="Pengumuman" translate="-translate-y-full"
        noBorder
        closeClick={toggleModal} isActive={isActive}>
            <ContentContainer className={`bg-white`}>

            </ContentContainer>
        </ModalWithClose>
    )
}
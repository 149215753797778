import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import TopBar from './TopBar'

export default function BackLayout({ title, children, className="", backFunction }){
    const navigate = useNavigate()

    return (
        <div className={`flex flex-col ${className}`}>
            <TopBar noBorder className='bg-primary sticky top-0 z-20 flex text-white items-center'>
                <div className='w-8 h-8 select-none flex items-center justify-center' 
                onClick={() => {backFunction ? backFunction(false) : navigate(-1)}}>
                    <ArrowLeftIcon className='w-2/3 stroke-white stroke-2'/>
                </div>
                <span className='flex-grow ml-4 text-xl h-full flex items-center'>{title}</span>
            </TopBar>
            { children }
        </div>
    )
}
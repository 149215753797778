import axios from 'axios'
import qs from 'qs'

const getRequest = (path, params) => {
  return axios.get(path, {
    params
  })
}

const getRequestWithArrParams = (path, params) => {
  return axios.get(path, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
}

const postRequest = (path, request, params) => {
  return axios.post(path, request, {
    params
  })
}

const putRequest = (path, request, params) => {
  return axios.put(path, request, {
    params
  })
}

const deleteRequest = (path, request) => {
  return axios.delete(path, {
    data: request
  })
}

const httpApi = {
  getRequest,
  getRequestWithArrParams,
  postRequest,
  putRequest,
  deleteRequest
}

export default httpApi

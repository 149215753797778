import httpApi from '@/utils/http-api'
import api from '@/api/config'

const url = api.homepage
const obj = {
    fetchCarousel() {
        const path = url.carousel
        return httpApi.getRequest(path)
    },
}

export default obj
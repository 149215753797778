import CustomHelmet from "../../layout/CustomHelmet";

export default function PageNotFound() {
    return (
        <div className="bg-white-background 
        text-primary flex justify-center items-center h-screen flex-col">
            <CustomHelmet themeColor="#fffbe4"/>
            <h1 className="text-6xl font-extrabold">404</h1>
            <h4 className="font-bold">page not found</h4>
        </div>
    )
}
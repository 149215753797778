import httpApi from '@/utils/http-api'
import api from '@/api/config'

const url = api.user
const obj = {
    userLogin(payload) {
        const path = url.login
        return httpApi.postRequest(path, payload)
    },
    userFetch() {
        const path = url.user
        return httpApi.getRequest(path, {
            withCredentials: true
        })
    },
    userLogout() {
        const path = url.logout
        return httpApi.getRequest(path)
    }
}

export default obj
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ReportLine from "./ReportLine"

export default function ReportGrid({ 
    width: inheritedGridWidth, height: inheritedGridHeight, noTopBorder,
    labelMultiplier:inheritedLabelMultiplier=1, 
    labelStart:inheritedLabelStart=0, lines=[], withNumber,
    withLabel, plusOneLabel, heightMultiplier:inheritedHeightMultiplier = 1, contents=[],
    noRounded, noBottomRounded, show=false, setShow
}){
    const gridWidth = parseInt(inheritedGridWidth)
    const gridHeight = parseInt(inheritedGridHeight)
    const labelMultiplier = parseInt(inheritedLabelMultiplier)
    const labelStart = parseInt(inheritedLabelStart)
    const heightMultiplier = parseInt(inheritedHeightMultiplier)

    const el = useRef(null)

    const [cell, setCell] = useState({
        width: 0,
        height: 0
    })

    useLayoutEffect(() => {
        function calculateAndSetCell(){
            let w = el.current.offsetWidth 
            const modulo = w % 2
            w -= modulo
            w /= 24
            w *= (24 / gridWidth)
            let h = Math.min(w, 15) * gridHeight
            h = Math.ceil(h)
            h /= gridHeight
            const newState = {
            width: w,
            height: h * heightMultiplier,
            }
            setCell(newState)
        }

        calculateAndSetCell()

        window.addEventListener('resize', calculateAndSetCell)
        return () => {
            window.removeEventListener('resize', calculateAndSetCell)
        }
    }, [gridWidth, gridHeight, heightMultiplier])

    useEffect(() => {
        setTimeout(() => {if(cell.width > 0) setShow(true)}, 1000)
    }, [cell, setShow])

    function getDelta(a, b){
        return Math.max(a, b) - Math.min(b, a)
    }

    function drawLabel({ from, to, label }, idx) {
        if(!label) return
        const deltaX = getDelta(from.x, to.x)
        const deltaY = getDelta(from.y, to.y)

        const lineContainerWidth = deltaX*cell.width
        const lineContainerHeight = deltaY*cell.height

        const deg = Math.ceil(Math.atan2(lineContainerHeight, lineContainerWidth) * 180 / Math.PI)

        return (
            <div key={`label${idx}`} className="absolute z-10 font-semibold text-xs tracking-widest flex justify-center items-center origin-center" 
                style={{
                    width: lineContainerWidth+'px', 
                    height: lineContainerHeight+'px', 
                    bottom: ''+(from.y*cell.height+cell.height / 2)+'px',
                    left: ''+(from.x*cell.width-cell.width / 2 )+'px',
                }}
            >
                <div className="font-bold" style={{transform: 'rotate(-'+deg+'deg)'}}>{label}</div>
            </div>
        )
    }

    function drawLine({ from, to, color, marginFrom=0, marginTo=0 }, idx) {
        const deltaX = getDelta(from.x, to.x)
        const deltaY = getDelta(from.y, to.y)

        const flip = from.y+marginFrom > to.y+marginTo
        const lineContainerWidth = deltaX*(cell.width+0.25)
        const marginTop = cell.height * (!flip ? marginTo : marginFrom)
        const marginBottom = cell.height * (!flip ? marginFrom : marginTo) * -1
        const lineContainerHeight = deltaY*(cell.height)+marginTop+marginBottom

        let additionalStyle = {}
        if(!flip) additionalStyle={bottom: cell.height*from.y+marginFrom*cell.height+'px'}
        else additionalStyle = {bottom: cell.height*from.y-lineContainerHeight+marginFrom*cell.height+'px'}
        return (
            <div key={idx} className={`absolute`} 
                style={{
                    width: lineContainerWidth+'px', 
                    height: lineContainerHeight+'px', 
                    left: cell.width * from.x,
                    zIndex: idx,
                    ...additionalStyle
                }}
            >
                { lineContainerHeight === 0 ?
                <div className="relative h-[2px] z-10 -translate-y-px" style={{backgroundColor: color}}/>
                : lineContainerWidth === 0 ?
                <div className="relative w-[2px] h-full z-10 -translate-x-[0.5px]" style={{backgroundColor: color}}/>
                : <ReportLine color={color} flip={flip} width={lineContainerWidth} height={lineContainerHeight}/> }
            </div>
        )
    }

    function drawLines() {
        return lines.map((line, idx) => {
            return(
                <div key={idx}>
                    {drawLabel(line, idx)}
                    {drawLine(line, lines.length-idx)}
                </div>
            )
        })
    }

    function drawContents() {
        return contents.map((content, idx) => {
            if(!content) return ''
            const {on, margin=0, render} = content
            return (
                <div key={idx} className="absolute text-[2.8vi] z-10 sm:text-xs"
                    style={{
                        width: cell.width,
                        height: cell.height,
                        left: on.x*cell.width+'px',
                        bottom: on.y*cell.height+margin*cell.height+'px'
                    }}>
                    <div className="relative w-full h-full">
                        {render}
                    </div>
                </div>
            )
        })
    }

    function renderLabel() {
        const labels = []
        if(withLabel)
        for(let i = 0; i < gridHeight + (plusOneLabel ? 1 : 0); i++)
            labels.push(
                <div key={i} className="flex justify-end items-center pr-0.5 text-right" style={{ height: cell.height+'px', fontSize: (cell.height*2/3)+'px' }}>
                    {(i * labelMultiplier) + labelStart}
                </div>
            )
        return <div className="absolute flex flex-col-reverse right-0" style={ plusOneLabel ? {top: '-'+cell.height/2+'px'} : {} }>{labels}</div>
    }

    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        if(!canvas) return
        if(!cell.width || !cell.height) return

        function drawCanvas(){
            const ctx = canvas.getContext('2d')
            const scale = Math.ceil(window.devicePixelRatio)
            canvas.width = Math.ceil(scale * cell.width * gridWidth)
            canvas.height = Math.ceil(scale * cell.height * gridHeight)

            const canvasWidth = canvas.width
            const canvasHeight = canvas.height
            ctx.scale(scale, scale)
    
            ctx.strokeStyle = "#333"
            ctx.fillStyle = "#333"
            ctx.font = "12px Arial";
            ctx.textAlign = "right";
            ctx.clearRect(0,0,canvasWidth,canvasHeight)
            ctx.lineWidth = 1
            
            for(let i = 0; i < gridWidth + 1; i++){
                ctx.beginPath()
                const x = Math.floor(i * cell.width) + 0.5 > canvasWidth / scale - 0.5 ? canvasWidth / scale - 0.5 : Math.floor(i * cell.width) + 0.5
                ctx.moveTo(x, 0)
                ctx.lineTo(x, canvasHeight / scale)
                ctx.stroke()
                if(!withNumber) continue
                if(i === 0) continue
                ctx.fillText(i + "", x - 2.5, canvasHeight / scale - 3.5)
            }
            for(let i = !noTopBorder ? 0 : 1; i < gridHeight + 1; i++){
                ctx.beginPath()
                const y = Math.floor(i * cell.height) + 0.5 > canvasHeight / scale - 0.5 ? canvasHeight / scale - 0.5 : Math.floor(i * cell.height) + 0.5
                ctx.moveTo(0, y)
                ctx.lineTo(canvasWidth / scale, y)
                ctx.stroke()
            }
        }
        
        drawCanvas()
    }, [cell.width, cell.height, gridWidth, gridHeight, noTopBorder, withNumber])

    function drawGrid() {
        return <canvas className="w-full h-full" width={cell.width * gridWidth} height={cell.height * gridHeight} ref={canvasRef}/>
    }

    return (
        <div className="relative min-h-[.75rem]">
            <div className={`${show ? 'hidden' : ''} absolute w-full h-full
            ${noRounded ? '' : noBottomRounded ? 'rounded-t' : noTopBorder ? 'rounded-b' : 'rounded'} 
            w-full bg-gray-300 animate-pulse text-xl`}/>
            <div className={`w-full flex justify-end text-gray-500 ${show ? '' : 'opacity-0'}`}>
                <div className={`relative w-5 `}>
                    {renderLabel()}
                </div>
                <div className="flex w-full relative z-0" ref={el}>
                    {drawGrid()}
                    {drawLines()}
                    {drawContents()}
                </div>
            </div>
        </div>
    )
}
import ContentContainer from "../../layout/ContentContainer";
import ModalWithClose from "../template/ModalWithClose";

export default function Notifications({ isActive, toggleModal }){
    return (
        <ModalWithClose title="Notifikasi" translate="-translate-y-full"
        noBorder closeClick={toggleModal} isActive={isActive}>
            <ContentContainer className={`bg-white`}>

            </ContentContainer>
        </ModalWithClose>
    )
}
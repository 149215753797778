import axios from 'axios'

import MockAdapter from 'axios-mock-adapter'
import data from '@/api-mock/mock-data'

const mock = new MockAdapter(axios)

const methodMap = {
  GET: 'onGet',
  PUT: 'onPut',
  POST: 'onPost',
  DELETE: 'onDelete'
}

function initiateMock() {
    if(process.env.REACT_APP_MOCK_ENABLED === 'false') return

    data.forEach(d => {
        const params = [d.url]
        switch (d.method) {
            case 'GET': params.push({ params: d.params })
                break
            case 'PUT' || 'POST': params.push(d.body)
                break
            default:
                break
        }
        mock[methodMap[d.method]](...params).reply(d.code, d.data)
    })
}

initiateMock()
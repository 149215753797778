import { XMarkIcon } from '@heroicons/react/24/outline'
import TopBar from './TopBar'

export default function CloseLayout({ id, className, title, closeClick, children, noBorder, noShadow }){

    return (
        <div id={id} className={`${className}`} style={{perspective: 0}}>
            <TopBar className={`bg-white z-40 sticky top-0 items-center text-primary`} noShadow={noShadow} noBorder={noBorder}>
                <div className='w-8 h-8 select-none flex items-center justify-center' onClick={closeClick}>
                    <XMarkIcon className='stroke-2 h-full w-full'/>
                </div>
                <div className='ml-1.5 mt-4 text-xl flex items-center font-bold'>{title}</div>
            </TopBar>
            {children}
        </div>
    )
}
import { UNIVERSAL_PUSH_CONFIRM_DIALOG, UNIVERSAL_REMOVE_CONFIRM_DIALOG } from "./types"

const toggleModal = (type) => {
    return async (dispatch) => {
        dispatch({type: type})
    }
}

const pushConfirmationDialog = (id, render) => {
    return async (dispatch) => {
        const payload = { id, render }
        dispatch({type: UNIVERSAL_PUSH_CONFIRM_DIALOG, payload: payload})
    }
}

const removeConfirmationDialog = (id) => {
    return async (dispatch) => {
        dispatch({type: UNIVERSAL_REMOVE_CONFIRM_DIALOG, payload: id})
    }
}

export {
    toggleModal,
    pushConfirmationDialog,
    removeConfirmationDialog
}
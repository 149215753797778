import * as types from "./types";
import states from './state'

const MAPPING = {
  [types.HOMEPAGE_CAROUSEL]: 'carousel',
}

const reducer = (state = states, action) => {
  const newState = {...state};
  newState[MAPPING[action.type]] = action.payload
  return newState
}

export * from './actions'
export default reducer
import { useEffect, useRef, useState } from 'react'
import ContentContainer from '../layout/ContentContainer'
import TopBar from '../layout/TopBar'
import { XMarkIcon, ArrowRightOnRectangleIcon, UserIcon, Cog6ToothIcon, InformationCircleIcon, ChevronRightIcon, LockClosedIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import Card from '../layout/Card'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../store/modules/user'
import Info from '../components/template/Info'
import { dialogModal } from '../utils/common-function'

function Content({ isActive, toggleModal, hidden, setHidden }){
    const dispatch = useDispatch()
    const user = useSelector(state => state['userReducer'].user)
    const [pageMode, setPageMode] = useState('')
    const timeout = useRef(null)

    const handleClick = () => {
        if(!pageMode) toggleModal()
        else setPageMode('')
    }

    useEffect(() => {
        if(isActive && !hidden) clearTimeout(timeout.current)
        if(!isActive && !hidden) 
            timeout.current = setTimeout(() => {
                setHidden(true)
            }, 500)
        if(isActive) setHidden(false)
    }, [isActive, hidden, setHidden])

    function togglePage(name) {
        setPageMode(state => state ? '' : name)
    }

    function logout() {
        dialogModal({
            title: "Kamu yakin untuk keluar?",
            message: "Jika kamu keluar, kamu harus memasukkan kembali informasi email dan kata sandi kamu.",
            type: "info",
            disableMaskClick: true,
            confirmButtonText: "Keluar"
        }).then(({confirmed}) => {
            if(!confirmed) return
            dispatch(userLogout())
            if(isActive) toggleModal()
        })
    }

    const profileItems = [
        ["akun", () => togglePage('Akun'), <UserIcon/>],
        ["preferensi", () => togglePage('Preferensi'), <Cog6ToothIcon/>],
        ["informasi & pusat bantuan", () => togglePage('Informasi & Pusat Bantuan'), <InformationCircleIcon/>],
        ["kebijakan privasi", () => togglePage('Kebijakan Privasi'), <LockClosedIcon/>],
        ["keluar", logout, <ArrowRightOnRectangleIcon/>, "text-red", "bg-red"],
    ]

    function renderProfileItems(from, to) {
        const renderItems = []

        for(let i = from; i < to; i++){
            const [title, func, icon, color="text-gray-500", iconBg="bg-gray-500"] = profileItems[i]
            renderItems.push(
                <div key={i} onClick={func} className={`${ i < to - 1 ? 'pb-2.5 border-b' : '' } 
                flex items-center group ${ i > from ? 'mt-2.5' : '' }`}>
                    <div className={`p-1.5 h-8 w-8 text-white ${iconBg} rounded`}>{icon}</div>
                    <div className={`tracking-wider flex items-center ${iconBg} bg-opacity-0 rounded-l group-active:bg-opacity-25 group-hover:bg-opacity-25 transition-colors duration-150 h-8 ml-2 pl-2 font-semibold ${color} flex-grow`}>{title.toUpperCase()}</div>
                    <div className={`p-1.5 h-8 w-8 ${color} ${iconBg} bg-opacity-0 rounded-r group-active:bg-opacity-25 group-hover:bg-opacity-25 transition-colors duration-150 `}><ChevronRightIcon/></div>
                </div>
            )
        }
        return renderItems
    }

    return (
        <>
            <div id="profile" className={`
                fixed left-0 bottom-0 z-50 flex flex-col bg-primary
                transition-[transform] duration-500 h-full w-screen
                shadow-2xl overflow-hidden
                ${isActive ? "" : "translate-x-full"}
            `}>
                <TopBar className={`items-center relative text-white flex`} noShadow noBorder>
                    <div className='absolute left-4 top-4 w-8 h-8 select-none flex items-center ' onClick={handleClick}>
                        { pageMode ? <ArrowLeftIcon className='stroke-2 h-2/3 w-2/3'/> : <XMarkIcon className='stroke-2 h-2/3 w-2/3'/> }
                    </div>
                    <div className='h-8 text-xl w-full flex items-center font-bold justify-center'>{pageMode ? pageMode : 'Profil'}</div>
                </TopBar>
                <div className={`relative ${pageMode ? 'h-0' : 'h-full' } overflow-hidden ease-in-out transition-[height] duration-500`}>
                    <ContentContainer className={`absolute top-0 left-0 w-full h-full flex flex-col items-center`}>
                        <div className='flex flex-col flex-grow items-center justify-center text-white'>
                            <div className='h-28 w-28 rounded-lg overflow-hidden shadow-lg'>
                                {
                                    user.photo ?
                                    <img src={user.photo} alt=''/>
                                    : <div className='border-8 text-primary bg-white border-white p-3'><UserIcon/></div>
                                }
                            </div>
                            <div className='relative z-10 flex flex-col items-center'>
                                <div className='text-xl mt-2 font-medium'>{user.fullName}</div>
                                { user.workPlace ? <div className='text-[10px]'>@{user.workPlace}</div> : '' }
                            </div>
                        </div>
                        <Card className='bg-white text-gray-500 text-sm w-full flex py-2 justify-evenly'>
                            <Info className="text-center w-1/2" label='PASIEN TERBANTU' value={user.patientHelped}/>
                            <div className='border-l'/>
                            <Info className="text-center w-1/2" label='DATA TERISI' value={user.dataInserted}/>
                        </Card>
                    </ContentContainer>
                </div>
                <ContentContainer className={`relative h-full transition-[height] ease-in-out duration-500 
                    bg-white pt-5 pb-8 text-xs rounded-t-2xl text-gray-500 `}>
                    <div className={`${pageMode ? 'opacity-0 pointer-events-none' : ''} transition-opacity duration-500 ease-in-out`}>
                        {renderProfileItems(0, 3)}
                        <div className='my-5'><div className="h-px bg-gray-500 flex-grow"/></div>
                        {renderProfileItems(3, 5)}
                    </div>
                    <ContentContainer className={`pt-5 pb-8 absolute left-0 top-0 w-full h-full ${pageMode ? '' : 'opacity-0 pointer-events-none'} transition-opacity duration-700 ease-in-out`}>
                        
                    </ContentContainer>
                </ContentContainer>
            </div>
        </>
    )
}

export default function Profile({ isActive:inheritedIsActive, ...props }) {
    const [hidden, setHidden] = useState(true)
    const [isActive, setIsActive] = useState(false)

    const firstRender = useRef(false)
    useEffect(() => {
        if(inheritedIsActive) setHidden(false)
        setTimeout(() => setIsActive(inheritedIsActive)
            , firstRender.current ? 0 : 50)
    }, [inheritedIsActive])
    return (
        <>
        { !hidden && <Content {...props} hidden={hidden} isActive={isActive} setHidden={setHidden}/> }
        </>
    )
}
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import exampleReducer from './modules/example'
import universalReducer from './modules/universal'
import userReducer from './modules/user'
import patientReducer from './modules/patient'
import reportReducer from './modules/report'
import homepageReducer from './modules/homepage'

const rootReducer = combineReducers({
  exampleReducer,
  universalReducer,
  userReducer,
  patientReducer,
  reportReducer,
  homepageReducer,
});

// // Use require.context() to import all of the feature folder index.js files
// const featureContext = require.context('./modules/', true, /\/.+\/index\.js$/);
// featureContext.keys().forEach((filePath) => {
//   // Get the feature folder name from the file path
//   const folderName = filePath.split('/').slice(-2, -1)[0];

//   // Import the feature folder index.js file and add its reducer to the root reducer
//   const module = featureContext(filePath);
//   const reducer = module.default;
//   rootReducer[folderName] = reducer;
// });

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export default store
const state = {
    isAddModalShown: false,
    isAddPatientModalShown: false,
    isAddReportModalShown: false,
    isUserModalShown: false,
    isNotificationModalShown: false,
    isAnnouncementModalShown: false,
    confirmationDialogs: []
}

export default state

import store from "../store"
import { v4 as uuidv4 } from "uuid"
import ConfirmationModal from "../components/template/ConfirmationModal"
import { pushConfirmationDialog, removeConfirmationDialog } from "../store/modules/universal"

export async function dialogModal(options) {
    const id = uuidv4()
    return new Promise((resolve) => {
        store.dispatch(pushConfirmationDialog(id, <ConfirmationModal {...options} resolve={resolve} id={id}/>))
    }).finally(() => {
        setTimeout(() => {
            store.dispatch(removeConfirmationDialog(id))
        }, 1000)
    })
}


const convertToDate = (date) => {
    const t = date.split(/[- :]/);
    const d = new Date(Date.UTC(t[2], t[1]-1, t[0], t[3], t[4], t[5]));
    return d
}

const getMonthName = (date) => {
    const formatter = new Intl.DateTimeFormat('id', { month: 'short' })
    const monthName = formatter.format(date)
    return monthName
}

const padNumber = (n, target = Math.log10(n) + 2) => {
    return n.toString().padStart(target, "0")
}

const getDateString = (date) => {
    if(!date) return ''
    const d = convertToDate(date)
    const monthName = getMonthName(d)
    return d.getDate() + ' ' + monthName + ', ' + d.getFullYear() + ' ' + padNumber(d.getUTCHours(), 2) + ':' + padNumber(d.getMinutes(), 2)
}

const getDate = (date) => {
    if(!date) return ''
    const d = convertToDate(date)
    const monthName = getMonthName(d)
    return d.getDate() + ' ' + monthName + ', ' + d.getFullYear()
}

const getTime = (date) => {
    if(!date) return ''
    const d = convertToDate(date)
    return  padNumber(d.getUTCHours(), 2) + ':' + padNumber(d.getMinutes(), 2)
}

const stopPropagation = (e) => {
    e.stopPropagation()
}

const generateEmptyArr = (n, m) => {
    const arr = []
    for(let i = 0; i < n; i++){
        const arr2 = []
        for(let j = 0; j < m; j++)
            arr2.push([])
        arr.push(arr2)
    }
    return arr
}

const calculateEucledian = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x1-x2,2) + Math.pow(y1-y2,2))
}

const patientDropdownOption = (o, className) => {
    return (
        <div className={`${className} border-b`}>
            <div className='text-[10px]'>{o.room}</div>
            <div className='font-semibold'>{o.text}</div>
        </div>
    )
}

const toggleBody = (flag) => {
    if(flag) {
        document.body.className = 'bg-white-background overflow-hidden'
        document.body.style.overflow = 'hidden'
    } else {
        document.body.className = 'bg-white-background'
        document.body.style.overflow = 'unset'
    } 
}

export {
    getDateString,
    getDate,
    getTime,
    stopPropagation,
    generateEmptyArr,
    calculateEucledian,
    patientDropdownOption,
    toggleBody,
    padNumber
}
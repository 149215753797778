import * as types from './types'
import api from '../../../api/controller/homepage'

const fetchCarousel = () => {
    return async (dispatch) => {
        const { data } = await api.fetchCarousel()
        dispatch({type: types.HOMEPAGE_CAROUSEL, payload: data})
    }
}

export {
    fetchCarousel
}
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Card from "../../layout/Card";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useEffect } from "react";
import { stopPropagation } from "../../utils/common-function";
import { removeConfirmationDialog } from "../../store/modules/universal";
import { useDispatch } from "react-redux";

export default function ConfirmationModal({
    message = "",
    title= "",
    type="",
    showCancelButton = true,
    disableMaskClick = false,
    confirmButtonText = "Ya",
    cancelButtonText = "Tidak",
    additionalComponent = <></>,
    resolve,
    id
}) {
    const dispatch = useDispatch()
    const [animation, setAnimation] = useState('')
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        setAnimation('animate-scale-in')
        setTimeout(() => setOpen(true), 500)
        return () => {
            dispatch(removeConfirmationDialog(id))
        }
    }, [dispatch, id])

    function respond(value) {
        setAnimation('animate-scale-out')
        resolve(value)
    }

    function maskClick() {
        if(!isOpen) return
        if(!disableMaskClick) {
            respond({dismissed: true})
            return
        }
        if(animation === 'animate-scale-out' || animation === 'animate-shake') return
        setAnimation('animate-shake')
        setTimeout(() => setAnimation(''), 300)
    }

    function getTypeIcon() {
        if(type === "info") return <InformationCircleIcon className="text-primary"/>
        if(type === "warning") return <ExclamationTriangleIcon className="text-orange"/>
        if(type === "danger") return <ExclamationCircleIcon className="text-red"/>
        if(type === "success") return <CheckCircleIcon className="text-green"/>
        if(type === "failed") return <XCircleIcon className="text-red" />
        return
    }

    function onConfirm() {
        respond({confirmed: true})
    }

    function onCancel() {
        respond({cancelled: true})
    }
    
    return (
        <div className={`${animation === 'animate-scale-out' ? 'pointer-events-none' : ''}
        p-8 fixed w-full h-full left-0 top-0
         z-[1000] flex justify-center items-center`} onClick={maskClick}>
            <Card className={`relative bg-white w-full overflow-hidden drop-shadow shadow-2xl ${animation} ${ isOpen ? '' : 'scale-0' }`} onClick={stopPropagation}>
                <div className="absolute right-0 p-3 pb-0 flex justify-end text-primary">
                    <XMarkIcon className="w-6 h-6 stroke-2" onClick={onCancel}/>
                </div>
                <div className="p-4 pt-3 bg-white">
                    <div className={`${type === "" ? 'hidden' : ''} flex justify-center`}>
                        <div className="h-16 w-16">
                            {getTypeIcon()}
                        </div>
                    </div>
                    <div className={`${title ? '' : 'hidden'} text-gray-500 text-center text-lg font-semibold mb-4`}>
                        {title}
                    </div>
                    <div className={`text-gray-500 pb-2 text-xs ${message ? '' : 'hidden'}`}>
                        {message}
                    </div>
                    {additionalComponent}
                    <div className="pt-4 flex font-medium">
                        <button onClick={onCancel} className={`${!showCancelButton ? 'hidden' : 'mr-1'} w-full border-primary border text-primary rounded-sm `}>{cancelButtonText}</button>
                        <button onClick={onConfirm} className={`${!showCancelButton ? '' : 'ml-1'} w-full bg-primary text-white rounded-sm`}>{confirmButtonText}</button>
                    </div>
                </div>
            </Card>
        </div>
    )
}
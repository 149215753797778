import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { userFetch } from '@/store/modules/user'
import { toggleModal } from '../store/modules/universal'
import Navbar from "./Navbar";
import AddModal from "../components/layout/AddModal";
import AddPatientModal from "../components/layout/AddPatientModal";
import AddReportModal from "../components/layout/AddReportModal";
import { useCallback, useEffect, useRef } from "react";
import CustomHelmet from "./CustomHelmet";
import Profile from "../pages/Profile";
import * as types from "../store/modules/universal/types";
import Notifications from "../components/layout/Notifications";
import Announcements from "../components/layout/Announcements";
import { toggleBody } from "../utils/common-function";

export default function BaseLayout(){
    document.body.className = 'bg-white-background'
    const user = useSelector((state) => state['userReducer'].user)
    const isAddModalShown = useSelector(state => state['universalReducer'].isAddModalShown)
    const isAddPatientModalShown = useSelector(state => state['universalReducer'].isAddPatientModalShown)
    const isAddReportModalShown = useSelector(state => state['universalReducer'].isAddReportModalShown)
    const isUserModalShown = useSelector(state => state['universalReducer'].isUserModalShown)
    const isAnnouncementModalShown = useSelector(state => state['universalReducer'].isAnnouncementModalShown)
    const isNotificationModalShown = useSelector(state => state['universalReducer'].isNotificationModalShown)
    const confirmationDialogs = useSelector(state => state['universalReducer'].confirmationDialogs)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    if(user === '' || user === undefined) {
        dispatch(userFetch()).catch(() => {
            navigate("/login")
        })
    }

    useEffect(() => {
        if(Cookies.get('auth')) return
        if(user === '' || user === undefined) navigate("/login")
    
    }, [user, navigate])

    const mounted = useRef(false)

    const localToggleAddModal = () => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_ADD_MODAL))
        if(isAddPatientModalShown) localToggleAddPatientModal()
        if(isAddReportModalShown) localToggleAddReportModal()
    }

    const localToggleAddPatientModal = useCallback(() => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL))
    }, [dispatch])

    const localToggleAddReportModal = useCallback(() => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_ADD_REPORT_MODAL))
    }, [dispatch])

    const localToggleUserModal = useCallback(() => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_USER))
    }, [dispatch])

    const localToggleNotificationModal = useCallback(() => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_NOTIFICATION_MODAL))
    }, [dispatch])

    const localToggleAnnouncementModal = useCallback(() => {
        dispatch(toggleModal(types.UNIVERSAL_TOGGLE_ANNOUNCEMENT_MODAL))
    }, [dispatch])

    useEffect(() => {
        if(mounted.current) return
        if(isAddPatientModalShown) localToggleAddPatientModal()
        if(isAddReportModalShown) localToggleAddReportModal()
        if(isAnnouncementModalShown) localToggleAnnouncementModal()
        if(isNotificationModalShown) localToggleNotificationModal()
        if(isUserModalShown) localToggleUserModal()
    
        mounted.current = true
    }, [isAddPatientModalShown, isAddReportModalShown, isUserModalShown, 
        isAnnouncementModalShown, isNotificationModalShown, localToggleAddPatientModal,
        localToggleAddReportModal, localToggleAnnouncementModal, localToggleNotificationModal,
        localToggleUserModal])

    useEffect(() => {
        toggleBody(isAddPatientModalShown || isAddReportModalShown || isUserModalShown || 
            isAnnouncementModalShown || isNotificationModalShown)
    }, [isAddPatientModalShown, isAddReportModalShown, isUserModalShown, 
        isAnnouncementModalShown, isNotificationModalShown])

    function closeAllModal() {
        if(isAddModalShown) localToggleAddModal()
        if(isAddPatientModalShown) localToggleAddPatientModal()
        if(isAddReportModalShown) localToggleAddReportModal()
    }

    function renderConfirmationDialogs() {
        return confirmationDialogs.map(({id, render}) => {
            return <div key={id}>{render}</div>
        })
    }

    return (
        <div className="flex flex-col min-h-screen">
            <CustomHelmet/>
            <div className="flex flex-col relative min-h-screen">
                <Outlet/>
            </div>
            <div className="z-50 fixed bottom-0 left-0 w-full">
                <div className="relative">
                    <AddModal isActive={isAddModalShown} toggleModal={localToggleAddModal} togglePatientModal={localToggleAddPatientModal} toggleReportModal={localToggleAddReportModal}/>
                </div>
                <Navbar toggleAddModal={localToggleAddModal} isAddModalShown={isAddModalShown} closeAddModal={closeAllModal} toggleUserModal={localToggleUserModal}/>
            </div>
            <AddPatientModal isActive={isAddPatientModalShown} toggleModal={localToggleAddPatientModal}/>
            <AddReportModal isActive={isAddReportModalShown} toggleModal={localToggleAddReportModal}/>
            <Profile isActive={isUserModalShown} toggleModal={localToggleUserModal}/>
            <Notifications isActive={isNotificationModalShown} toggleModal={localToggleNotificationModal}/>
            <Announcements isActive={isAnnouncementModalShown} toggleModal={localToggleAnnouncementModal}/>
            { renderConfirmationDialogs() }
        </div>
    )
}
import api from '../../api/config'

const patient = api.patient
const obj = [
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getAll,
        'data': {
            patients: [
                {id: 1, name: 'Pasien1', room: 'A102', hospitalizedDate: '29-07-2023 15:37:43', priority: 1},
                {id: 2, name: 'Pasien2', room: 'A103', hospitalizedDate: '29-07-2023 15:37:43', priority: 2},
                {id: 3, name: 'Pasien3', room: 'A104', hospitalizedDate: '29-07-2023 15:37:43', priority: 3},
            ],
            paging: {
                page: 2,
                totalPage: 3
            }
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getSearchOptions,
        'data': [
            {value: 1, text: 'Pasien1', room: 'A102'},
            {value: 2, text: 'Pasien2', room: 'A103'},
            {value: 3, text: 'Pasien3', room: 'A104'},
        ]
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getInfo(1),
        'data': {
            handphone: '(+62) 85912345678',
            email: 'mamajin@gmail.com',
            address: 'Jalan Raya Tol Cikunir'
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getById(1),
        'data': 
        {id: 1, name: 'Pasien1', room: 'A102', hospitalizedDate: '29-07-2023 15:37:43', priority: 1}
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getInfo(2),
        'data': {
            handphone: '(+62) 85912345678',
            email: 'mamajin@gmail.com',
            address: 'Jalan Raya Tol Cikunir'
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getById(2),
        'data': 
        {id: 1, name: 'Pasien2', room: 'A103', hospitalizedDate: '29-07-2023 15:37:43', priority: 2}
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getInfo(3),
        'data': {
            handphone: '(+62) 85912345678',
            email: 'mamajin@gmail.com',
            address: 'Jalan Raya Tol Cikunir'
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': patient.getById(3),
        'data': 
        {id: 1, name: 'Pasien3', room: 'A104', hospitalizedDate: '29-07-2023 15:37:43', priority: 3}
    }
]

export default obj
import Home from "@/pages/Home"
import Login from '@/pages/Login'
import BaseLayout from "../layout/BaseLayout"
import Patients from "../pages/Patients"
import Patient, { loader as patientLoader } from "../pages/Patient"
import PageNotFound from "../pages/DefaultState/PageNotFound"
import Reports from "../pages/Reports"
import Schedules from "../pages/Schedules"

const router = [
    {
        path: '',
        element: <BaseLayout />,
        errorElement: <PageNotFound/>,
        children: [
            {
                path: '',
                element: <Home />
            },
            {
                path: 'patient',
                element: <Patients/>,
            },
            {
                path: 'patient/:id',
                element: <Patient/>,
                loader: patientLoader
            },
            {
                path: 'report',
                element: <Reports/>
            },
            {
                path: 'schedule',
                element: <Schedules/>
            }
        ]
    },
    {
        path: 'login',
        element: <Login/>
    }, 
    {
        path: '*',
        element: <BaseLayout />,
        children: [
            {
                path: '*',
                element: <PageNotFound />
            },
        ]
    },
]

export default router
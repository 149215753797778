import api from '../../api/config'

const homepage = api.homepage
const obj = [
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': homepage.carousel,
        'data': [
            {
                'title': 'Berita Terkini',
                'url': 'https://sehatnegeriku.kemkes.go.id/baca/rilis-media/20230806/2643614/menkes-temui-elon-musk-untuk-bangun-akses-internet-di-puskesmas-terpencil/',
                'text': 'Menkes Temui Elon Musk Untuk Bangun Akses Internet di Puskesmas Terpencil',
                'image': 'https://sehatnegeriku.kemkes.go.id/wp-content/uploads/2023/08/IMG-20230806-WA0007-350x250.jpg',
                'source': 'https://sehatnegeriku.kemkes.go.id/topik/berita-utama/'
            },
            {
                'title': 'Berita Terkini',
                'url': 'https://sehatnegeriku.kemkes.go.id/baca/rilis-media/20230805/4143611/pemerintah-pusat-jembatani-masalah-insentif-dokter-di-rsud-haulussy-ambon/',
                'text': 'Pemerintah Pusat Jembatani Masalah Insentif Dokter di RSUD Haulussy Ambon',
                'image': 'https://sehatnegeriku.kemkes.go.id/wp-content/uploads/2023/08/IMG-20230805-WA0000-350x250.jpg',
                'source': 'https://sehatnegeriku.kemkes.go.id/topik/berita-utama/'
            },
            {
                'title': 'Berita Terkini',
                'url': 'https://sehatnegeriku.kemkes.go.id/baca/rilis-media/20230803/5543608/tersertifikasi-iso-27001-platform-satusehat-diakui-di-100-negara/', 
                'text': 'Tersertifikasi ISO 27001, Platform SATUSEHAT Diakui di 100 Negara',
                'image': 'https://sehatnegeriku.kemkes.go.id/wp-content/uploads/2023/08/53053326327_031090d986_c-350x250.jpg',
                'source': 'https://sehatnegeriku.kemkes.go.id/topik/berita-utama/'
            }
        ]
    },
]

export default obj
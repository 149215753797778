import { useEffect, useState } from 'react'
import InputDropdown from '../template/InputDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOptions } from '../../store/modules/patient'
import InputNumber from '../template/InputNumber'
import HorizontalSeparator from '../../layout/HorizontalSeparator'
import { dialogModal, patientDropdownOption } from '../../utils/common-function'
import ModalWithClose from '../template/ModalWithClose'
import CustomInput from '../template/CustomInput'

const inputClass="text-sm"

const partograf = [
    [
        'title',
        'Informasi Diperlukan',
        [
            ['number', 'DJJ', 'fetalHeartRate'],
            ['number', 'Nadi', 'pulse'],
            ['number', 'Jumlah Kontraksi', 'contractionTimes'],
            ['number', 'Durasi Kontraksi', 'contractionDuration'],
        ]
    ],
    [
        'title',
        'Opsional (Kecuali Pengisian Pertama)',
        [
            ['number', 'Pembukaan Serviks', 'cervixOpening'],
            ['number', 'Turun Kepala', 'headDrop'],
            ['title', 'Tekanan Darah', [
                ['number', 'Batas Bawah', 'bloodPressureMin'],
                ['number', 'Batas Atas', 'bloodPressureMax'],
            ]]
        ]
    ],
    [
        'title',
        'Opsional',
        [
            ['dropdown', 'Air Ketuban', 'amnioticFluid', [
                {'value': 'U', 'text': 'Utuh'},
                {'value': 'J', 'text': 'Jernih'},
                {'value': 'M', 'text': 'Mekonium'},
                {'value': 'D', 'text': 'Darah'},
                {'value': 'K', 'text': 'Kering'},
            ]],
            ['number', 'Penyusupan', 'infiltration'],
            ['text', 'Obat & Cairan IV', 'medicationsAndIVFluid'],
            ['number', 'Temperatur', 'temperature'],
            ['title', 'Oksitosin', [
                ['number', 'U/I', 'oxytocinUI'],
                ['number', 'Tetes/Menit', 'oxytocinDrops'],
            ]],
            ['title', 'Urin', [
                ['number', 'Protein', 'urineProtein'],
                ['number', 'Aseton', 'urineAseton'],
                ['number', 'Volume', 'urineVolume'],
            ]]
        ]
    ]
]
const birthRecordPhase1 = [
    ['title', 'Catatan Persalinan', [
        ['textarea', 'Alasan Merujuk', 'referReason'],
        ['text', 'Tempat Merujuk', 'referPlace'],
        ['dropdown', 'Pendamping pada saat merujuk', 'referAccompany', [
            {'value': 'midwife', 'text': 'Bidan'},
            {'value': 'family', 'text': 'Keluarga'},
            {'value': 'friend', 'text': 'Teman'},
            {'value': 'shaman', 'text': 'Dukun'},
            {'value': 'none', 'text': 'Tidak ada'},
        ]],
        ['dropdown', 'Masalah dalam kehamilan / persalinan', 'pregnancyProblem', [
            {'value': 'emergency', 'text': 'Gawat Darurat'},
            {'value': 'infection', 'text': 'Infeksi'},
            {'value': 'bleeding', 'text': 'Pendarahan'},
            {'value': 'hdr', 'text': 'HDR'},
            {'value': 'pmtct', 'text': 'PMTCT'},
        ]]
    ]],
    ['title', 'KALA I', [
        ['dropdown', 'Partograf melewati garis waspada', 'alertLine', [
            {'value': 1, 'text': 'Ya'},
            {'value': 0, 'text': 'Tidak'}
        ]],
        ['textarea', 'Masalah lain', 'phase1OtherProblem'],
        ['textarea', 'Penanganan masalah', 'phase1ProblemResolution'],
        ['textarea', 'Hasil', 'phase1Result']
    ]]
]
const birthRecordPhase2 = [
    ['title', 'Kala II', [
        ['dropdown-customizable', 'Episiotomi', 'episiotomy', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true},
            {'value': false, 'text': 'Tidak'}
        ], ['text', 'Alasan episiotomi', 'episiotomyReason']],
        ['dropdown', 'Pendamping pada saat persalinan', 'birthAccompany', [
            {'value': 'hausband', 'text': 'Suami'},
            {'value': 'family', 'text': 'Keluarga'},
            {'value': 'friend', 'text': 'Teman'},
            {'value': 'shaman', 'text': 'Dukun'},
            {'value': 'none', 'text': 'Tidak ada'},
        ]],
        ['dropdown-customizable', 'Episiotomi', 'fetalDistress', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true, 'customizableIndex': 0},
            {'value': false, 'text': 'Tidak'},
            {'value': 'monitoring', 'text': 'Pemantauan DJJ setiap 5-10 menit selama kala II', 'showCustomizable': true, 'customizableIndex': 1},
        ], [
            ['textarea', 'Tindakan yang dilakukan', 'fetalDistressNote'],
            ['text', 'Hasil', 'fetalDistressNote']
        ]],
        ['dropdown-customizable', 'Distosia bahu', 'dystocia', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true},
            {'value': false, 'text': 'Tidak'}
        ], ['text', 'Tindakan yang dilakukan', 'dystociaAction']],
        ['textarea', 'Masalah lain', 'phase2OtherProblem'],
        ['textarea', 'Penanganan masalah', 'phase2ProblemResolution'],
        ['textarea', 'Hasil', 'phase2Result']
    ]]
]
const birthRecordPhase3 = [
    ['title', 'Kala III', [
        ['dropdown-customizable', 'Inisiasi Menyusui Dini', 'earlyBreastfeeding', [
            {'value': true, 'text': 'Ya'},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true}
        ], ['text', 'Alasannya', 'notBreastfeedReason']],
        ['fill-in-number', ['Lama kala III', 'menit'], 'phase3Duration'],
        ['dropdown-customizable', 'Pemberian Oksitosin 10U IM', 'oxytocinAdministration', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true, 'customizableIndex': 0},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true, 'customizableIndex': 1},
        ], [
            ['fill-in-number', ['Waktu:', 'menit sesudah persalinan'], 'oxytocinAdministrationNote'],
            ['text', 'Alasan', 'oxytocinAdministrationNote']
        ]],
        ['dropdown-customizable', 'Pemberian ulang Oksitosin (2x)', 'oxytocinAdministration2', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true},
            {'value': false, 'text': 'Tidak'},
        ], ['text', 'Alasan', 'oxytocinAdministrationNote2']],
        ['dropdown-customizable', 'Penegangan tali pusat terkendali', 'cordControlled', [
            {'value': true, 'text': 'Ya'},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true},
        ], ['text', 'Alasan', 'cordControlledNote']],
        ['dropdown-customizable', 'Masase fundus uteri', 'uterineFundalMassage', [
            {'value': true, 'text': 'Ya'},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true},
        ], ['text', 'Alasan', 'uterineFundalMassageNote']],
        ['dropdown-customizable', 'Plasenta lahir lengkap', 'placentaIntact', [
            {'value': true, 'text': 'Ya'},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true},
        ], ['textarea', 'Tindakan yang dilakukan', 'placentaNotIntactAction']],
        ['dropdown-customizable', 'Masase fundus uteri', 'placentaNotDelivered', [
            {'value': true, 'text': 'Ya'},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true},
        ], ['text', 'Tindakan', 'placentaNotDeliveredNote']],
        ['dropdown-customizable', 'Laserasi', 'laser', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true},
            {'value': false, 'text': 'Tidak'},
        ], [
            ['text', 'Tempat laser', 'laserLocation'],
            ['dropdown', 'Derajat', 'laserDegree', [
                {'value': 1, 'text': 1},
                {'value': 2, 'text': 2},
                {'value': 3, 'text': 3},
                {'value': 4, 'text': 4},
            ]],
            ['dropdown-customizable', 'Tindakan sesudah laser', 'laserAction', [
                {'value': 'dressmakingWithAnesthesia', 'text': 'Penjahitan dengan anestesia'},
                {'value': 'dressmakingWithoutAnesthesia', 'text': 'Penjahitan tanpa anestesia'},
                {'value': 'noDressmaking', 'text': 'Tidak dijahit', 'showCustomizable': true},
            ], ['text', 'Alasan', 'noDressmakingReason']],
        ]],
        ['dropdown-customizable', 'Atonia uteri', 'uterineAtony', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true},
            {'value': false, 'text': 'Tidak'},
        ], ['text', 'Tindakan', 'uterineAtonyAction']],
        ['fill-in-number', ['Pendarahan', 'ml'], 'bloodLoss'],
        ['textarea', 'Masalah lain', 'phase3OtherProblem'],
        ['textarea', 'Penanganan masalah', 'phase3ProblemResolution'],
        ['textarea', 'Hasil', 'phase3Result']
    ]]
]
const birthRecordPhase4 = [
    ['title', 'Kala IV', [
        ['fill-in-number', ['KU'], 'motherKU'],
        ['fill-in-number', ['TD', 'mmHg'], 'motherTD'],
        ['fill-in-number', ['Nadi', 'x / menit'], 'motherPulse'],
        ['fill-in-number', ['Napas', '/ menit'], 'motherBreath'],
        ['textarea', 'Masalah lain', 'phase4OtherProblem'],
        ['textarea', 'Penanganan masalah', 'phase4ProblemResolution'],
        ['textarea', 'Hasil', 'phase4Result']
    ]],
    ['title', 'Bayi Baru Lahir', [
        ['fill-in-number', ['Berat badan', 'gram'], 'infantWeight'],
        ['fill-in-number', ['Panjang badan', 'cm'], 'infantHeight'],
        ['dropdown', 'Jenis kelamin', 'infantGender', [
            {'value': 'L', 'text': 'Laki-laki'},
            {'value': 'P', 'text': 'Perempuan'},
        ]],
        ['dropdown', 'Penilaian bayi baru lahir', 'infantEvaluation', [
            {'value': 'good', 'text': 'Baik'},
            {'value': 'some complications', 'text': 'Ada penyulit'}
        ]],
        ['dropdown-customizable', 'Pemberian ASI setelah jam pertama bayi lahir', 'breastfeedingAtFirstHour', [
            {'value': true, 'text': 'Ya', 'showCustomizable': true, 'customizableIndex': 0},
            {'value': false, 'text': 'Tidak', 'showCustomizable': true, 'customizableIndex': 1},
        ], [
            ['fill-in-number', ['Waktu:', 'jam setelah bayi lahir'], 'breastfeedingAtFirstHourNote'],
            ['text', 'Alasan', 'breastfeedingAtFirstHourNote']
        ]],
        ['textarea', 'Masalah lain', 'newbornOtherProblem'],
        ['textarea', 'Penanganan masalah', 'newbornProblemResolution'],
        ['textarea', 'Hasil', 'newbornResult']
    ]]
]
const formTemplate = [partograf, birthRecordPhase1, birthRecordPhase2, birthRecordPhase3, birthRecordPhase4]

function Dropdown({value, text, options, handleChange, form}) {
    const [isOpen, setOpen] = useState(false)

    return (
        <div className={`text-sm border-b mt-6`}>
            <InputDropdown setOpen={setOpen} className='mx-2 py-0.5' border={isOpen} value={form[value]} onChange={handleChange} placeholderMove name={value} 
            placeholder={text} options={options}/>
        </div>
    )
}

const reportTypeOptions = [
    {value: 0, text: 'Partograf'}, 
    {value: 1, text: 'Catatan Persalinan'},
    {value: 2, text: 'Catatan Persalinan - Kala II'},
    {value: 3, text: 'Catatan Persalinan - Kala III'},
    {value: 4, text: 'Catatan Persalinan - Kala IV'},
]
export default function AddReportModal({ isActive, toggleModal }){
    const dispatch = useDispatch()
    const searchOptions = useSelector(state => state['patientReducer'].options)
    const [patientDropdownOpen, setPatientDropdownOpen] = useState(false)
    const [form, setForm] = useState({})
    const [isOpen, setOpen] = useState(false)
    const [typeOpen, setTypeOpen] = useState(false)
    const [type, setType] = useState(0)
    const [searchText, setSearchText] = useState('')

    const handleMaskClick = () => {
        setOpen(false)
        toggleModal()
    }

    useEffect(() => {
        dispatch(fetchOptions({search: searchText}))
    
    }, [dispatch, searchText, isOpen])

    useEffect(() => {
        if(!isActive) return
        setForm({})
        setType(0)
    }, [isActive])

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target
        setForm(state => ({
            ...state,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }

    const handleSearchChange = (newValue) => {
        setSearchText(newValue)
        if(newValue) dispatch(fetchOptions({search: newValue}))
    }

    function addReport() {
        dialogModal({
            type: 'info',
            title: 'Apakah kamu sudah yakin?',
            message: 'Data yang sudah terkirim nanti tidak dapat diganti lho...'
        }).then(({confirmed}) => {
            if(!confirmed) return
            console.log('asd')
        })
    }

    function getComponent(type, text, value, options, depth, first, last) {
        if(type === 'title') 
            return (
                <div key={text}>
                    <HorizontalSeparator title={text} className={`
                        ${!depth ? `text-primary font-semibold ${first ? 'mt-8' : 'mt-16'}` : 'mt-6'}
                    `}/>
                    { generateField(value, depth+1) }
                </div>
            )
        if(type === 'number')
            return (
                <div className={`${depth < 2 ? '' : first ? 'mr-2' : last ? 'ml-2' : 'mx-2'} w-full`} key={text}>
                    {
                        <InputNumber className={`${inputClass} mt-6`} 
                        value={form[value]} onChange={handleChange} placeholderMove name={value} 
                        placeholder={text}/>
                    }
                </div>
            )
        if(type === 'text')
            return <div key={text} className='mt-6 py-1 border-b'><CustomInput className={`${inputClass} px-2`} 
            value={form[value]} onChange={handleChange} placeholderMove name={value} 
            placeholder={text}/></div>
        if(type === 'dropdown')
            return <Dropdown key={text} value={value} text={text} options={options} handleChange={handleChange} form={form} />
        if(type === 'fill-in-number')
            return <div key={text} className={`${inputClass} flex mt-6 text-gray-500`}>
                <div className='py-1 border-b'>{text[0]}</div>
                <div className='flex-grow'>
                    <InputNumber className='' textAlign='text-center'
                    value={form[value]} onChange={handleChange} name={value}/>
                </div>
                <div className='py-1 border-b'>{text[1]}</div>
            </div>
    }

    function generateField(from=partograf, depth = 0) {

        const arr = from
        const fields = []
        for(let i = arr.length - 1; i >= 0; i--){
            const [type, text, value, options] = arr[i]
            fields.push(
                getComponent(type, text, value, options, depth, i === 0, i === arr.length - 1)
            )
        }
        return <div className={`flex ${depth} ${depth > 1 ? 'flex-row-reverse' : 'flex-col-reverse'}`}>{fields}</div>
    }

    return (
        <>
            <ModalWithClose title="Tambah Data" func={addReport}
            noBorder helmetActive={isActive}
            closeClick={handleMaskClick} isActive={isActive}>
                <div className='flex flex-col-reverse'>
                    <div className={`bg-white h-12 shadow-lg text-sm ${patientDropdownOpen ? 'rounded-t' : 'rounded' }`}>
                        <InputDropdown autoChange border
                            setOpen={setPatientDropdownOpen} className='mx-2' 
                            withSearch handleSearchChange={handleSearchChange}
                            options={searchOptions} customOption={patientDropdownOption}
                            value={form.patient} onChange={handleChange} 
                            name="patient" id="patient" placeholder="Pilih Pasien"
                        />
                    </div>
                    <div className='flex justify-between'>
                        <div className={`font-semibold ${form.patient ? 'text-primary' : 'text-gray-400'} flex items-end`}>
                            Pasien
                        </div>
                        <div className='pb-1.5'>
                            <div className={`bg-white w-48 text-xs text-gray-500 shadow
                                    ${typeOpen ? 'rounded-t' : 'rounded'}
                                `}>
                                <InputDropdown labelColor="text-white" alwaysShow autoChange border
                                onChange={({target}) => {
                                    setType(target.value)
                                    setForm({})
                                }} className="mx-2 py-0.5"
                                value={type} placeholder="Tipe Laporan" setOpen={setTypeOpen} name='reportType'
                                options={reportTypeOptions}/>
                            </div>
                        </div>
                    </div>
                </div>
                {generateField(formTemplate[type])}
            </ModalWithClose>
        </>
    )
}
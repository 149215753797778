import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toggleModal } from "../store/modules/universal"
import { useDispatch, useSelector } from "react-redux"
import { UserPlusIcon, UserIcon } from '@heroicons/react/24/outline'
import { fetchAllPatients, setPatient } from "../store/modules/patient/actions"
import TopBar from "../layout/TopBar"
import ContentContainer from "../layout/ContentContainer"
import { getDateString, patientDropdownOption } from "../utils/common-function"
import Card from "../layout/Card"
import InputDropdown from "../components/template/InputDropdown"
import { UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL } from "../store/modules/universal/types"

const patientTypeOptions = [{value: 1, text: 'Aktif'}, {value: 2, text: 'Tidak Aktif'}]
export default function Patients(){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fetchedPatients = useSelector(state => state['patientReducer'].patients)
    const patientPaging = useSelector(state => state['patientReducer'].paging)
    const searchOptions = useSelector(state => state['patientReducer'].options)
    const [isOpen, setOpen] = useState(false)
    const [patients, setPatients] = useState([])
    const [typeOpen, setTypeOpen] = useState(false)
    const [type, setType] = useState(1)
    const [params, setParams] = useState({page: 1, search: ''})

    const localToggleAddModal = () => {
        dispatch(toggleModal(UNIVERSAL_TOGGLE_ADD_PATIENT_MODAL))
    }

    useEffect(() => {
        function fetchNext() {
            dispatch(fetchAllPatients(params))
        }

        fetchNext()
    }, [dispatch, params])

    useEffect(() => {
        if(params.page !== 1) setPatients(p => p.concat(fetchedPatients))
        else setPatients(fetchedPatients)
    }, [fetchedPatients, patientPaging, params.page])

    
    function handleSearchChange(newValue) {
        setParams(state => ({ ...state, search: newValue }))
    }

    function navigateToPatient(patient){
        dispatch(setPatient(patient))
        navigate("/patient/" + patient.id)
    }

    const renderPatients = () => {
        return patients.filter(p => p.name.includes(params.search) || p.room.includes(params.search))
        .map((patient, idx) => {
            return (
                    <div className={`bg-white transform-gpu text-xs drop-shadow-lg p-3 rounded ${idx === 0 ? "" : "mt-2"}`} 
                    onClick={() => navigateToPatient(patient)} key={idx}>
                    <div className="flex">
                        <div className="relative flex justify-center items-center w-14 min-h-[3.5rem] overflow-hidden rounded mr-3">
                            <UserIcon className="absolute h-full border-4 border-gray-300 bg-gray-300 text-white"/>
                        </div>
                        <div className="flex flex-grow flex-col justify-between">
                            <div className={`text-center px-2 py-px rounded text-white font-semibold
                                ${type - 1 ? 'hidden' : ''} 
                                ${patient.priority === 3 ? "bg-green" :
                                patient.priority === 2 ? "bg-orange" :
                                "bg-red"}
                            `}>PRIORITAS { patient.priority }</div>
                            <div className="text-gray-500 font-bold text-base">{ patient.name }</div>
                        </div>
                    </div>
                    <div className="flex mt-1 justify-between tracking-widest">
                        <div className="text-left">
                            <div className="text-[10px] text-gray-400 underline">TANGGAL MASUK</div>
                            <div className="font-bold text-gray-500">{ getDateString(patient.hospitalizedDate) }</div>
                        </div>
                        <div className="text-right">
                            <div className="text-[10px] text-gray-400 underline">RUANG</div>
                            <div className="font-bold text-gray-500">{ patient.room }</div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            <div className="sticky -top-px z-10">
                <TopBar className="pb-0 bg-primary flex text-xl items-end justify-between font-bold text-white tracking-wide" noShadow noBorder>
                    <div>Daftar Pasien</div>
                </TopBar>
                <div className="flex justify-end px-4 bg-primary">
                    <div className={`bg-white w-28 text-xs text-gray-500 mb-1
                            ${typeOpen ? 'rounded-t-sm' : 'rounded-sm'}
                        `}>
                        <InputDropdown labelColor="text-white" alwaysShow
                        onChange={({target}) => setType(target.value)}  className="mx-2 py-0.5"
                        value={type} placeholder="Tipe Laporan" setOpen={setTypeOpen}
                        options={patientTypeOptions}/>
                    </div>
                </div>
                <div className="px-4 relative z-10">
                    <div className="absolute -top-px left-0 h-1/2 w-full bg-primary shadow-lg"/>
                    <Card className="bg-white flex relative z-0">
                        <div className={`flex-grow transition-[width] text-sm bg-white ${isOpen ? 'rounded-t' : 'rounded' }`}>
                            <InputDropdown 
                                isOpen={isOpen} setOpen={setOpen} className='mx-3' 
                                withSearch handleSearchChange={handleSearchChange}
                                options={searchOptions} customOption={patientDropdownOption}
                                value={params.search} noRevert
                                name="patientSearch" id="patient" placeholder="Pasien"
                            />
                        </div>
                        <div onClick={localToggleAddModal} className={`${ isOpen ? 'w-0' : 'w-12 border-l' } 
                        transition-[width] h-12 text-primary select-none cursor-pointer overflow-hidden relative`}>
                            <div className="h-12 w-12 absolute top-0 left-0 flex items-center justify-center">
                                <UserPlusIcon className="w-7 h-7"/>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <ContentContainer className="">
                { renderPatients() }
            </ContentContainer>
        </>
    )
}
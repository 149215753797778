import './App.css';
import {
  createHashRouter as createRouter,
  RouterProvider,
} from "react-router-dom";
import router from '@/router'

function App() {
  return (
    <div className='w-full min-h-screen flex flex-col'>
      <RouterProvider router={createRouter(router)}/>
    </div>
  );
}

export default App;

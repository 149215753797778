import api from '../../api/config'

const user = api.user
const obj = [
    {
        'code': 200,
        'status': 'OK',
        'method': 'POST',
        'url': user.login,
        'body': {
            'email': 'admin',
            'password': 'admin',
            'rememberMe': true
        },
        'data': {
            'photo': 'https://scontent.fcgk28-1.fna.fbcdn.net/v/t39.30808-6/300999005_3258663331057896_4227985661404991745_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeHk4POLgLRynV2XXqCj2SRp-FpYoergDiz4Wlih6uAOLDs-IWhq6LAYTM27dx3-d-WHr3nqRyP-kf6_ACk3Ur8r&_nc_ohc=GW_7lDjUmx8AX92GbRC&_nc_ht=scontent.fcgk28-1.fna&oh=00_AfDJHYjpxJiXTu2Y3P2eWyizlcu-1UYgA9PW5hGo0GBTsA&oe=64EF2F55',
            'nickname': 'Daffa',
            'fullName': 'Muhamad Daffa Mennawi',
            'workPlace': 'RSUP Persahabatan',
            'patientHelped': 217,
            'dataInserted': 4898
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': user.user,
        'data': {
            'photo': 'https://scontent.fcgk28-1.fna.fbcdn.net/v/t39.30808-6/300999005_3258663331057896_4227985661404991745_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeHk4POLgLRynV2XXqCj2SRp-FpYoergDiz4Wlih6uAOLDs-IWhq6LAYTM27dx3-d-WHr3nqRyP-kf6_ACk3Ur8r&_nc_ohc=GW_7lDjUmx8AX92GbRC&_nc_ht=scontent.fcgk28-1.fna&oh=00_AfDJHYjpxJiXTu2Y3P2eWyizlcu-1UYgA9PW5hGo0GBTsA&oe=64EF2F55',
            'nickname': 'Daffa',
            'fullName': 'Muhamad Daffa Mennawi',
            'workPlace': 'RSUP Persahabatan',
            'patientHelped': 217,
            'dataInserted': 4898
        }
    },
    {
        'code': 200,
        'status': 'OK',
        'method': 'GET',
        'url': user.logout
    },
]

export default obj
const REPORT_SET = 'report/SET'
const REPORT_SET_PATIENT = 'report/SET_PATIENT'
const REPORT_SET_PATIENT_NAME = 'report/SET_PATIENT_NAME'
const REPORT_SET_NOTE = 'report/SET_NOTE'

export {
    REPORT_SET,
    REPORT_SET_PATIENT,
    REPORT_SET_NOTE,
    REPORT_SET_PATIENT_NAME,
}